import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { isEmpty } from '../utils/utilsShared';
import { DialogMsgComponent } from '../component/message/dialog-msg/dialog-msg.component';



export interface MessageAction {
    id?:any;
    dialog?: boolean;
    config?: any;
    snackBar?: {
        vrPosition: string;
        hrPosition: string;
        panelClass: string | string[];
        duration:number;
    };
    message?: string;
    translate?: boolean;
    title?: any;
    dialogRef?:boolean;
    template?:number;
}


@Injectable({ providedIn: 'root'})
export class MessageObserverService {
    private message = new BehaviorSubject<MessageAction>({
        config: undefined,
        dialog: false,
        dialogRef: false,
        id: undefined,
        message: '',
        snackBar: { duration: 0, hrPosition: '', panelClass: undefined, vrPosition: '' },
        title: undefined,
        translate: false
    });
     dialog: MatDialogRef<DialogMsgComponent, any>

    constructor() {
    }

    watchMessage(): Observable<MessageAction> {
        return this.message.asObservable();
    }

    addMessage(messageAction: MessageAction) {
         if(isEmpty(messageAction.template)){
             messageAction.template=1

         }
        this.message.next(messageAction);
    }
}
