import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { CartElement } from '../../../models/cart-element';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'tw-cart-header',
    templateUrl: './cart-header.component.html',
    styleUrls: ['./cart-header.component.css']
})
export class CartHeaderComponent implements OnInit {

    @Input() cartElement:any| CartElement;
    @Input() type: any;
    iconShow = true;
    totalOriginal = 0;
    hasPromo = false;
    total = 0;

    constructor(elementRef: ElementRef, renderer: Renderer2, public translate: TranslateService) {
        renderer.listen(elementRef.nativeElement, 'click', (event) => {
            // Do something with 'event'
            this.iconShow = event.returnValue;
        });
    }

    ngOnInit() {
        this.total = this.getTotal();
    }

    getTotal() {
        let temp = 0;
        this.totalOriginal = 0;
        this.cartElement.prices.forEach(p => {
            const qty = p.donation ? 1 : p.cartDetailToken.length;
            temp = temp + (p.price + p.serviceCharges) * qty;
            if (p.originalPrice > 0) {
                this.totalOriginal = this.totalOriginal + ((p.originalPrice - p.price) * qty);

            }
        });
        if (this.totalOriginal > 0) {
            this.totalOriginal = (temp + this.totalOriginal) / 100;
        }
        return temp / 100;
    }

    getTickets() {
        let temp = 0;
        this.cartElement.prices.forEach(p => {
            temp = temp + (p.cartDetailToken.length);
        });
        return temp;
    }

}
