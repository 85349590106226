<div style="margin: 2px;color: black">
    <div class="btn-floating-order mat-elevation-z2 " style="display: flex;justify-content: center;
    background: white;
    "
         [ngStyle]="!splitPrices && !bestsSelect ? {'flexDirection':'column'}:{}"

         id="{{nameUnique}}">


        <div style="width: 100%;">
            <div style="
            padding: 10px;
            font-weight: bold;display: flex;flex-direction: row;flex-wrap: wrap;align-content: center;justify-content: end"
                 class="temp-taxes-stats">
                <div class="titleTax">
                    @if (translate.currentLang === 'fr') {
                        <span>* Tous les prix incluent les taxes.</span>
                    }
                    @if (translate.currentLang !== 'fr') {
                        <span>* All prices include tax.</span>
                    }

                    <!--                <span>{{ 'cart.addToCart' | translate }} <span *ngIf="total>0">{{total / 100 | currency :'':'symbol-narrow':'':translate.currentLang}}</span></span>-->
                </div>
                <div style="align-items: center;justify-content: right" class="tw-flex tw-flex-flow-row-wrap">
                    @if (qtyReserved > 0) {
                        <div class="tw-flex   tw-column" style="
justify-content: center;
    align-items: center;
    margin-right: 5px;
">
                            @if (qtyReserved === 1) {
                                <div class="tw-primary-color" style="    text-align: right;
    width: 100%">
                                    {{ qtyReserved }}
                                    @if (onlyGiftCertificates){
                                        {{""|libTranslateLang : 'Gift Certificate ' : 'Certificat de cadeaux'}}
                                    }  @else{
                                         {{ 'title.tickets'| translate }}
                                    }
                                </div>
                            }
                            @if (qtyReserved > 1) {

                                <div class="tw-primary-color" style="    text-align: right;
    width: 100%"> {{ qtyReserved }}
                                    @if (onlyGiftCertificates){
                                        {{""|libTranslateLang : 'Gift Certificates ' : 'Certificats de cadeaux'}}
                                    }  @else{
                                        {{ 'title.tickets'| translate }}
                                    }
                                </div>
                            }
                            @if (total && translate.currentLang === 'fr') {
                                <div style="font-weight: bold;font-size: larger">Total
                                    : {{ total / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                                </div>
                            }
                            @if (total && translate.currentLang !== 'fr') {
                                <div style="font-weight: bold;font-size: larger">
                                    Total: {{ total / 100 | currency :'':'symbol-narrow':'':translate.currentLang }}
                                </div>
                            }
                        </div>
                    }
                    <div class="tw-flex">
                        @if (pageSaleService.page?.display === 'ITEMS') {
                            @if (activeBtn) {
                                <button style="height: auto;min-height: 40px" mat-raised-button color="primary"
                                        (click)="createHoldPage()">
                                    <mat-icon class="icon-add">
                                        add_shopping_cart
                                    </mat-icon>
                                    <span>{{ 'cart.addToCart' | translate }}</span>

                                    @if (!load) {
                                        <div class="cssload-speeding-wheel"></div>
                                    }
                                </button>
                            } @else {
                                <button style="height: auto;min-height: 40px" mat-raised-button color="primary"
                                        (click)="goToCart()">
                                    <mat-icon class="icon-add">
                                        shopping_cart_checkout
                                    </mat-icon>

                                    <span>{{ 'title.cart' | translate }}</span>

                                </button>
                            }
                        } @else {
                            <button style="height: auto;min-height: 40px" mat-raised-button color="primary"
                                    (click)="createHoldPage()"
                                    [disabled]="!activeBtn" >
                                <mat-icon class="icon-add">
                                    add_shopping_cart
                                </mat-icon>
                                <span>{{ 'cart.addToCart' | translate }}</span>

                                @if (!load) {
                                    <div class="cssload-speeding-wheel"></div>
                                }
                            </button>
                        }

                    </div>
                </div>
            </div>
        </div>


        @if (!splitPrices && !bestsSelect && isDialog) {
            <div>
                @if (!load) {
                    <mat-progress-bar></mat-progress-bar>
                }
                <button mat-raised-button color="primary"
                        (click)="closeDialog()" style="width: 100%"
                >
                    <span>{{ 'title.next' | translate }}</span>
                </button>
            </div>

        }
    </div>
</div>

