import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    MatSnackBar,
    MatSnackBarConfig,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { DialogMsgComponent } from './dialog-msg/dialog-msg.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import {
    MessageAction,
    MessageObserverService,
} from '../../services/message-observer.service';
import { isEmpty } from '../../utils/utilsShared';

@Component({
    selector: 'lib-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.css'],
})
export class MessageComponent implements OnInit {
    setAutoHide = true;
    autoHide = 2500;
    addExtraClass = false;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    opnedDialog = false;

    constructor(
        public dialog: MatDialog,
        private messageObserverService: MessageObserverService,
        public snackBar: MatSnackBar,
    ) {}

    ngOnInit() {
        console.log('MessageComponent');

        this.messageObserverService.watchMessage().subscribe((value) => {
            if (isEmpty(value.message) || this.opnedDialog) {
                return;
            }
            if (value.dialogRef) {
                this.dialog.closeAll();
            }

            console.log(value, this.opnedDialog);

            if (value.dialog) {
                this.opnedDialog = true;
                const dy = this.dialog.open(DialogMsgComponent, {
                    data: {
                        message: value.message,
                        translate: value.translate,
                        title: value.title,
                        id: value.id,
                    },
                });
                if (value.dialogRef) {
                    this.messageObserverService.dialog = dy;
                }

                dy.afterClosed().subscribe((result) => {
                    this.opnedDialog = false;
                });
            }
            if (value.snackBar) {
                this.opnedDialog = true;
                const snbk = this.snackBar.openFromComponent(
                    SnackBarComponent,
                    this._createConfig(value),
                );

                snbk.afterDismissed().subscribe((value) => {
                    console.log(value);
                    this.opnedDialog = false;
                });
            }
        });
    }

    private _createConfig(data: MessageAction) {
        const config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;
        // config.panelClass = this.addExtraClass ? ['demo-party'] : undefined;
        // config.direction = 500;
        config.panelClass = data.snackBar.panelClass || 'tw-snack-bar';
        config.data = data;
        if (data.snackBar && data.snackBar.duration) {
            config.duration = data.snackBar.duration;
        }
        ///console.log(data)
        return config;
    }
}
