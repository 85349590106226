import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { DrawSeatMapsService } from '../draw-seat-maps.service';

import { SeatMapService } from '../seat-map.service';
import * as d3 from 'd3-selection';
import { select, selectAll } from 'd3-selection';
import * as d3Zoom from 'd3-zoom';
import * as d3org from 'd3';
import { BehaviorSubject } from 'rxjs';

import { Platform } from '@angular/cdk/platform';
import { SyosVenueDetails } from '../../../models/syos-venue-details';
import { SyosSeat } from '../../../models/syos-seat';
import { SeatsSelected } from '../../../models/seats-selected';
import { SyosSection } from '../../../models/syos-section';
import { ZoomInterface, ZoomService } from '../../control-zoom/zoom.service';
import { MatMenu } from '@angular/material/menu';
import {
    deleteSeatsSelected,
    getReelNbQtyStep,
} from '../../../utils/utilsShared';

@Component({
    selector: 'lib-draw-seatsmap-sale',
    templateUrl: './draw-seatsmap-sale.component.html',
    styleUrls: ['./draw-seatsmap-sale.component.css'],
})
export class DrawSeatsmapSaleComponent implements OnInit, OnDestroy {
    constructor(
        public seatMapService: SeatMapService,
        public _platform: Platform,
        public drawSeatMapsService: DrawSeatMapsService,
    ) {
        DrawSeatsmapSaleComponent._platform = _platform;
        this.isMobil = _platform.ANDROID || _platform.IOS;
        //this.isMobil = true;
        // DrawSeatsmapSaleComponent.zoomActive =  !(_platform.ANDROID || _platform.IOS);
    }

    static zoomActive = true;
    static _platform: any;
    static _smallSeatMap = false;

    static watchStartSeatsManager = new BehaviorSubject(null);
    onSeatsSelected = false;
    @ViewChild('menu') myMenu: MatMenu;
    @Input() venue: SyosVenueDetails;
    @Input() eventId: number;
    @Input() isSubscription = false;
    @Input() filterPriceLevels: number[] = [];
    qtySelected = 0;
    @Input() httpParams;
    @Input() cartService;
    @Input() websiteSaleService;

    @Output() messages = new EventEmitter();
    width = 900;
    height = 800;
    scaleFactor;
    originalScaleFactor = 40;
    seatSizeFactor = 0.4;
    d3 = d3;
    zoom = null;

    seatsHover: SyosSeat[];
    seatSelected: SeatsSelected = <SeatsSelected>{};
    listSeatSelectedLocal: SeatsSelected[] = <SeatsSelected[]>[];
    isMobil = false;
    activeSelectSeats = true;
    MIN_SHOW_POLYGON = 0;
    sub = [];
    //venueIds = [1420077251, 1471129903, 1480227252, 1536906552];
    facilityId = [1411850201];
    startZoom = false;
    load = false;
    positionToolTip = {
        x: 0,
        y: 0,
    };

    zoomedV4 = (event: { transform: any }) => {
        if (DrawSeatsmapSaleComponent.zoomActive) {
            const t = event.transform;
            const isMobil =
                DrawSeatsmapSaleComponent._platform.ANDROID ||
                DrawSeatsmapSaleComponent._platform.IOS;

            ZoomService.zoomCurrent = t;
            console.log(t)
            DrawSeatMapsService.containerSvg.attr('transform', t);
            //console.log(event?.sourceEvent?.type);
            if (this.startZoom && !this.seatMapService.smallSeatsMap) {
                const aa = DrawSeatMapsService.getPolygonShowInMap(this.venue);
                //console.log(aa);
                DrawSeatsmapSaleComponent.watchStartSeatsManager.next(
                    new Date().getTime(),
                );
                if (!DrawSeatMapsService.isPolygonView) {
                    //  console.log("sssssssssssssssssssss",d3.event.sourceEvent.type)
                }
            }
        }
    };

    ngOnInit() {
        //  console.log('ngOnInit');
        this.onSeatsSelected = false;
        let zoom;

        zoom = d3org.zoom().scaleExtent([0.1, 3]).on('zoom', this.zoomedV4);

        // console.log(typeof zoom);

        // @ts-ignore
        this.width = document.querySelector('#chartCt').offsetWidth;

        // @ts-ignore
        this.height = document.querySelector('#chartCt').offsetHeight;

        // if (this.width < 600) {
        //     this.height = this.width * 1.3;
        // }
        //console.log(this.width, this.height);
        //console.log(this.width);
        DrawSeatMapsService.filterPriceLevels = this.filterPriceLevels;
        DrawSeatMapsService.createConstructor(
            d3,
            zoom,
            this.width,
            this.height,
        );
        this.drawSeatMap();
        setTimeout(() => {
            this.cartService?.getCart().subscribe();
            if (this.seatMapService.getSubSeatSelected()?.length > 0) {
                this.addStyleForHoverOrSelectSeat(
                    this.seatMapService.getFormatSubSeatSelected(
                        this.listSeatSelectedLocal,
                    ),
                    true,
                );
            }
        }, 2000);

        const a = this.cartService?.cartSub
            .asObservable()
            .subscribe((value) => {
                if (value) {
                    DrawSeatMapsService.addReserveTag(
                        value,
                        this.eventId,
                        this.isSubscription,
                    );
                }
            });

        const b = this.seatMapService
            .watchQtySeatMapBySelect()
            .subscribe((value) => {
                this.load = true;
                setTimeout(() => {
                    this.changeQtySelect(value);
                }, 2000);

                // console.log(value);
            });

        const d = this.seatMapService
            .watchSubBestSeatSelect()
            .subscribe((value) => {
                this.activeSelectSeats = !value;
            });

        const f = DrawSeatMapsService.watchZoomPolygons
            .asObservable()
            .subscribe((value) => {
                if (value !== null) {
                    // console.log('////////////////////////////////////////////');
                    setTimeout(() => {
                        DrawSeatsmapSaleComponent.watchStartSeatsManager.next(
                            new Date().getTime(),
                        );
                        //   this.setSeatEventClic();
                    }, 2000);
                }
            });
        const j = DrawSeatMapsService.watchZoom
            .asObservable()
            .subscribe((value) => {
                //  console.info("watchZoom",value)
            });
        const k = DrawSeatMapsService.listenEventsSeats
            .asObservable()
            .subscribe((value) => {
                if (value) {
                    this.manageSeatsEvent(value);
                }
            });
        const l = DrawSeatsmapSaleComponent.watchStartSeatsManager
            .asObservable()
            .subscribe((value) => {
                if (value && !DrawSeatsmapSaleComponent._smallSeatMap) {
                    this.doStartEventSeats();
                }
            });

        const p = ZoomService.watchZoom.asObservable().subscribe((value) => {
             console.log(value);
            const t = ZoomService.bodySvg;

            if (t && ZoomService && ZoomService.instance) {
                ZoomService.zoomCurrent = value.zoomIdentity;
                // @ts-ignore
                // ZoomService.bodySvg.transition()
                //     .duration(1750)
                //     .call(ZoomService.instance.transform, value.zoomIdentity);
                ZoomService.instance.transform(
                    ZoomService.bodySvg,
                    value.zoomIdentity,
                );
            }
        });
        const aa = this.seatMapService
            .watchSeatSelected()
            .subscribe((value: SeatsSelected[]) => {
                this.removeStyleForHoverOrSelectSeat(
                    this.getReserveTemp(),
                    true,
                );
                this.listSeatSelectedLocal = value?.filter(
                    (value) => value.eventId === this.eventId,
                );

                this.addStyleForHoverOrSelectSeat(
                    this.seatMapService.getFormatSubSeatSelected(
                        this.listSeatSelectedLocal,
                    ),
                    true,
                );
            });
        const bb = this.seatMapService.subSeatSelectedToRemove
            .asObservable()
            .subscribe((value) => {
                value?.seats?.forEach((seatSelect) => {
                    seatSelect?.data?.forEach((seat) => {
                        this.preRemoveSeat(seat);
                    });
                });
                // this.removeSeatReserveTemp(value.seats[0]);
            });
        const dd = DrawSeatMapsService.priceLevelSelected.subscribe((value) => {
            if (value === null) {
                return;
            }
            if (value && value > 0) {
                this.filterPriceLevels = [value];
            } else {
                this.filterPriceLevels = [];
            }

            this.changeQtySelect(this.qtySelected, true);
        });
        this.sub = [a, b, d, f, j, k, l, p, aa];
    }

    private changeQtySelect(value: number, active = false) {
        try {
            if (this.qtySelected !== value || active) {
                this.qtySelected = value;
                const enableSt = this.enableSeat();

                let p: d3.Selection<d3.BaseType, unknown, HTMLElement, any>;
                let combinedNodes = [];
                //console.log(this.filterPriceLevels);
                if (this.filterPriceLevels.length > 0) {
                    this.filterPriceLevels.forEach((value) => {
                        const nodes = selectAll(
                            '.priceLevelId-' + value,
                        ).nodes();
                        combinedNodes = combinedNodes.concat(nodes);
                    });
                } else {
                    p = selectAll('.sellable');
                }
                if (combinedNodes.length > 0) {
                    p = selectAll(combinedNodes);
                }
                selectAll('.circle-seat').classed('not-sellable-seat', true);
                if (this.qtySelected > 1) {
                    const pSeat = [];
                    p.each((seatData: { data: any }, j) => {
                        const temp = DrawSeatMapsService.getValidSeatsOnSale(
                            seatData.data,
                            this.qtySelected,
                            this.venue.avoidSingle,
                            enableSt,
                            this.seatMapService.subValidQtyDemande.value,
                        );
                        //console.log(temp);
                        temp.forEach((value1) => {
                            const idSeat = value1.id;

                            if (
                                pSeat.find((st) => st.id === idSeat) ===
                                undefined
                            ) {
                                pSeat.push(value1);
                                select('#circle-seat' + idSeat).classed(
                                    'not-sellable-seat',
                                    false,
                                );
                            }
                        });
                    });
                } else {
                    //selectAll('.circle-seat').classed('not-sellable-seat', false);
                    p.each((seatData: { data: any }, j) => {
                        select('#circle-seat' + seatData.data.id).classed(
                            'not-sellable-seat',
                            false,
                        );
                    });
                }
                if (this.listSeatSelectedLocal?.length > 0) {
                    this.addStyleForHoverOrSelectSeat(
                        this.seatMapService.getFormatSubSeatSelected(
                            this.listSeatSelectedLocal,
                        ),
                        true,
                    );
                }

                this.clearSeats();
            }
        } catch (e) {
            this.load = false;
        }

        this.load = false;
    }

    ngOnDestroy(): void {
        //console.log('remove seat map');
        //this.seatMapService.subSeatSelected.next(null);
        d3.selectAll('.' + DrawSeatMapsService.constSeatMap.SEAT)
            .on('touchstart', null)
            .on('touchmove', null)
            .on('touchcancel', null)
            .on('click', null)
            .on('mouseover', null)
            .on('mouseout', null)
            .remove();
        d3.select('#venue' + this.venue.id).remove();
        this.clearSeats();
        this.sub.forEach((value) => {
            value?.unsubscribe();
        });
    }

    doStartEventSeats() {
        const svgVenue = d3.select('#venue' + this.venue.id);
        // DrawSeatMapsService.removeAllSeatsReset()
        DrawSeatMapsService.drawSeatsInPolygonZoomOut(
            svgVenue,
            this.venue,
            this.eventId,
            this.isSubscription,
            this.httpParams,
        ).finally(() => {
            this.addStyleForHoverOrSelectSeat(
                this.seatMapService.getFormatSubSeatSelected(
                    this.listSeatSelectedLocal,
                ),
                true,
            );
        });
    }

    updateSeatWithPrice() {
        this.venue.levels.forEach((level) => {
            level.sections.forEach((section) => {
                // console.log(this.eventId);
                DrawSeatMapsService.getPriceForSeatBySectionId(
                    this.eventId,
                    this.isSubscription,
                    <SyosSection>{ id: section.id },
                    this.venue,
                    this.httpParams,
                );
            });
        });
    }

    doCenterSeatsMap() {
        const a = document.querySelector('#chart');
        // @ts-ignore
        const bbox2 = a.getBoundingClientRect();

        const vw = bbox2.width; // container width
        const vh = bbox2.height; // container height
        const bbox = DrawSeatMapsService.containerSvg.node().getBBox();
        const bw = bbox.width;
        const bh = bbox.height;
        const x = Math.floor(bbox.x + bbox.width / 2.0);
        const y = Math.floor(bbox.y + bbox.height / 2.0);

        //console.log(bbox2, bbox);
        const scale = Math.max(
            0,
            Math.min(8, 0.9 / Math.max(bw / vw, bh / vh)),
        );
        const translate = [vw / 2 - scale * x, vh / 2 - scale * y];
        ZoomService.scaleInit = d3Zoom.zoomIdentity
            .translate(translate[0], translate[1])
            .scale(scale);
        ZoomService.watchZoom.next(<ZoomInterface>{
            zoomIdentity: d3Zoom.zoomIdentity
                .translate(translate[0], translate[1])
                .scale(scale),
            action: 'm',
        });
        setTimeout(() => {
            this.startZoom = true;
        }, 2000);

        // DrawSeatMapsService.bodySvg.transition(transition);
        // .call(zoom.translate(translate).scale(scale).event); // not in d3 v4
    }

    manageSeatsEvent(value: {
        event: string;
        listener: any;
        data: { data: SyosSeat; priceLevelId: number };
    }) {
        this.positionToolTip.x = value.listener.pageX;
        this.positionToolTip.y = value.listener.pageY;
        //console.log(this.positionToolTip);
        document.querySelector('.tooltip');
        if (!value) {
            return;
        }
        if (!this.activeSelectSeats) {
            return;
        }

        if (value.event === 'click') {
            //console.log('click');
            if (!this.seatMapService.subOptionMultiSelect.value) {
                this.removeStyleForHoverOrSelectSeat(
                    this.seatMapService.getFormatSubSeatSelected(
                        this.listSeatSelectedLocal,
                    ),
                    true,
                );
                this.seatMapService.clearSubSeatSelected(this.eventId);
            } else {
                const st = value?.data?.data;
                /* quand les sièges sont deselection  */
                //this.removeSeatReserveTemp(st);
                const isAlreadySeatSelected =
                    this.seatMapService.isSelectedSeat(st.id);
                if (!this.seatsHover || this.seatsHover.length === 0) {
                    /**
                     * Ce bloc de code gère la sélection des sièges dans un lieu, en particulier lorsque la propriété `avoidSingle` est activée.
                     * Cette propriété signifie qu'un siège unique ne peut pas être laissé non sélectionné entre deux sièges sélectionnés.
                     *
                     * @param {SyosSeat} st - Le siège actuel à traiter.
                     *
                     * Le processus est le suivant :
                     * 1. Vérifie si la propriété `avoidSingle` du lieu est activée.
                     * 2. Si c'est le cas, il valide les autres sièges s'ils sont valides dans le même range.
                     * 3. Il récupère les sièges actuellement sélectionnés (`sLocal`) et les trie en fonction de leur ordre.
                     * 4. Il trouve l'index du siège actuel (`st`) dans la liste triée des sièges sélectionnés.
                     * 5. Il itère ensuite sur la liste triée des sièges sélectionnés. Si le siège actuel est dans la même rangée (`rowId`) que le siège dans l'itération, il vérifie si le siège actuel est dans la première moitié ou la seconde moitié de la liste. En fonction de sa position, il ajoute soit des sièges de la position actuelle à la fin de la liste, soit du début de la liste à la position actuelle à l'array `rowSeats`.
                     * 6. Il crée ensuite un array `remainingSeats` qui contient les sièges qui ne sont pas dans l'array `rowSeats`.
                     * 7. Il décide ensuite quels sièges filtrer (`nfilter`) en fonction de la longueur de `remainingSeats` et `rowSeats`. Si `remainingSeats` a plus de sièges, il utilise `rowSeats`, sinon il utilise `remainingSeats`. Si le siège actuel n'est pas dans `remainingSeats`, il l'ajoute.
                     * 8. Si `nfilter` a plus de 3 sièges, il le réduit pour n'inclure que le siège actuel et un siège à côté de lui, en fonction de sa position dans le tableau.
                     * 9. Enfin, il itère sur l'array `nfilter` et supprime la réservation temporaire pour chaque siège dans celui-ci.
                     *
                     * Si `avoidSingle` n'est pas vrai, il supprime simplement la réservation temporaire pour le siège actuel (`st`).
                     */
                    this.preRemoveSeat(st);
                }
                if (isAlreadySeatSelected) {
                    /* si le siège est déjà sélectionné on le supprime dans le cas un mobile il nya pas des hover seats ***/
                    return;
                }
            }

            if (this.isMobil) {
                //  this.clearSeats();
                //** en force les seathover car n'excite pas le event hover mobile ***/
                const website = this.websiteSaleService?.getLocalConfig();
                //const selectionPeers = website.accountCode === 'STE';
                const enableSt = this.enableSeat();

                const seatsHoverTemp = DrawSeatMapsService.getValidSeatsOnSale(
                    value.data.data,
                    this.qtySelected,
                    this.venue.avoidSingle,
                    enableSt,
                    this.seatMapService.subValidQtyDemande.value,
                );
                this.seatsHover = this.clearSeatSelectFromHoverSeat(
                    seatsHoverTemp,
                    this.qtySelected,
                );
                //console.log(this.seatsHover.length);
                if (this.seatsHover.length > 0) {
                    this.addStyleForHoverOrSelectSeat(this.seatsHover);
                }
            }

            //console.log(this.seatsHover.length);
            if (this.seatsHover && this.seatsHover.length > 0) {
                this.seatSelected.data = this.seatsHover;
                this.addSeatReserveTemp(value);
            }
            this.seatsHover = [];
            this.addStyleForHoverOrSelectSeat(
                this.seatMapService.getFormatSubSeatSelected(
                    this.listSeatSelectedLocal,
                ),
                true,
            );
        }
        if (value.event === 'mouseover') {
            //console.log('mouseover-----', DrawSeatMapsService?.priceLevelSelected?.value);
            if (!value?.data?.priceLevelId) {
                return;
            }
            if (DrawSeatMapsService?.priceLevelSelected?.value) {
                if (value.data && value.data.priceLevelId) {
                    if (
                        DrawSeatMapsService.priceLevelSelected.value !==
                        value.data.priceLevelId
                    ) {
                        return;
                    }
                }
            }
            //console.log(value.data.data);
            const website = this.websiteSaleService?.getLocalConfig();
            // const selectionPeers = website.accountCode === 'STE';

            let enableSt = this.enableSeat();
            const temp = DrawSeatMapsService.getValidSeatsOnSale(
                value.data.data,
                this.qtySelected,
                this.venue.avoidSingle,
                enableSt,
                this.seatMapService.subValidQtyDemande.value,
            );
            // console.log(temp);
            this.seatsHover = this.clearSeatSelectFromHoverSeat(
                temp,
                this.qtySelected,
            );
            //console.log(this.seatsHover.length);
            if (this.seatsHover) {
                this.addStyleForHoverOrSelectSeat(this.seatsHover);
            }
            this.activeTooltip();
        }
        if (value.event === 'mouseout') {
            this.removeStyleForHoverOrSelectSeat(this.seatsHover);
            this.clearTooltip();
        }
    }
    preRemoveSeat(st: SyosSeat) {
        if (this.venue.avoidSingle) {
            /* valide les autres sièges s'ils sont valides dans le meme range */
            //console.log(st,this.seatsHover)

            let sLocal = this.seatMapService.getFormatSubSeatSelected(
                this.listSeatSelectedLocal,
            );
            // sLocal = sLocal.filter((value) => value.rowId === st.rowId);
            // sLocal = sLocal.sort((a, b) => {
            //     return a.order - b.order;
            // });
            let nfilter = deleteSeatsSelected(sLocal, st, this.qtySelected);

            nfilter?.forEach((seat) => {
                // console.log(seat.name);
                this.removeSeatReserveTemp(seat);
            });
        } else {
            this.removeSeatReserveTemp(st);
        }
    }
    private removeSeatReserveTemp(seat: SyosSeat) {
        if (!seat) {
            return;
        }
        const hasSeatToRemove = this.seatMapService.removeSubSeatSelectedById(
            seat.id,
        );
        this.removeStyleForHoverOrSelectSeat(hasSeatToRemove, true);
    }
    addSeatReserveTemp(value: {
        event: string;
        listener: any;
        data: { data: SyosSeat; priceLevelId: number };
    }) {
        if (
            value.data &&
            value.data.priceLevelId &&
            value.data.priceLevelId > 0
        ) {
            const temp = this.seatMapService.getPriceLevel(
                this.venue.priceLevels,
                value.data.priceLevelId,
            );
            //console.log(temp);
            this.seatSelected.prices = temp.prices;

            const hasNeedSplitPrice = this.seatSelected.prices.length > 1;
            // this.seatSelected.prices[0].maxTickets=2
            console.log(
                this.seatMapService.getFormatSubSeatSelected(
                    this.listSeatSelectedLocal,
                ),
            );

            this.seatSelected.prices.forEach((value1, index) => {
                /** le premier prix c'est le choix par default.
                 *  s'il y a plusieurs choix le client vas choisir le prix souhaite dans un autre page
                 */
                if (index === 0) {
                    value1.qty = this.qtySelected;
                    value1.maxQty = this.qtySelected;
                    value1.seatIds = this.seatSelected.data.map((value2) => {
                        const note = DrawSeatMapsService.getNote(value2.id);
                        value2.note = note;
                        return value2.id;
                    });
                    this.seatSelected.seatIds = this.seatSelected.data.map(
                        (value2) => value2.id,
                    );
                    value1.confirmed = !hasNeedSplitPrice;
                } else {
                    value1.qty = 0;
                    value1.maxQty = 0;
                }
            });
            this.seatSelected.notes = [];
            this.seatSelected.seatIds.forEach((idSeat) => {
                const note = DrawSeatMapsService.getNote(idSeat);
                if (note) {
                    this.seatSelected.notes.push(note);
                }
            });
            this.seatSelected.priceLevelId = value.data.priceLevelId;
            this.seatSelected.colorPriceLevel = temp.color;
            this.seatSelected.prices[0].qty =
                this.seatSelected.prices[0].seatIds.length;
            if (this.seatSelected.prices.length === 1) {
                this.seatSelected.data.forEach((value1) => {
                    value1.priceSelected = this.seatSelected.prices[0];
                });
            }
        }
        //console.log(value.listener.x,value.listener.y)
        this.seatSelected.left = value.listener.x;
        this.seatSelected.top = value.listener.y;
        //console.log(this.seatSelected);
        this.seatSelected.eventId = this.eventId;
        this.seatMapService.addSubSeatSelected(this.seatSelected);
    }

    enableSeat() {
        //return false;
        return (
            this.facilityId.find((ids) => ids === this.venue.facilityId) !==
            undefined
        );
    }

    clearSeatSelectFromHoverSeat(syosSeat: SyosSeat[], qtySelected: number) {
        let temp = syosSeat.filter(
            (value) => !this.hasSeatSelectedById(value.id),
        );
        if (!temp && temp.length === 0) {
            return [];
        }
        if (this.filterPriceLevels?.length > 0) {
            temp = temp.filter((value) =>
                this.filterPriceLevels.includes(value.priceLevelId),
            );
            //console.log(temp.length,qtySelected)
        }
        qtySelected = getReelNbQtyStep(qtySelected, temp[0]?.minTicket);
        if (
            (temp.length > 0 && temp.length <= qtySelected) ||
            temp.length === 2 || temp.length === 3
        ) {
            return syosSeat;
        }
        return [];
    }

    hasSeatSelectedById(id): boolean {
        let list = <SeatsSelected[]>this.seatMapService.subSeatSelected.value;
        if (!list) {
            return false;
        }
        list = list.filter((value) => value.eventId === this.eventId);
        return list.find((value) => value.seatIds.includes(id)) !== undefined;
    }

    addStyleForHoverOrSelectSeat(list?: SyosSeat[], select = false) {
        //console.log(list.length);
        list.forEach(function (seat) {
            const seatSvg = DrawSeatMapsService.getSeat(seat.id);

            const circle = seatSvg.select('circle');

            circle.style('fill', 'rgb(255,171,0)');
            circle.style('stroke-width', '1');
            if (select) {
                seatSvg.classed('reserve-temp', true);
                circle.classed('not-sellable-seat', false);
            }
        });
    }
    getReserveTemp() {
        let a: SyosSeat[] = [];
        const oo = selectAll('.reserve-temp')?.each((value: any, index) => {
            //console.log(value);
            if (value?.data?.id) {
                a.push(<any>{ id: value?.data.id });
            }
        });
        return a;
    }
    removeStyleForHoverOrSelectSeat(seatsHover: SyosSeat[], select = false) {
        // console.log('removeStyleForHoverOrSelectSeat', seatsHover);
        if (!seatsHover || seatsHover.length === 0) {
            return;
        }
        //console.log(this.venue.id);
        seatsHover.forEach(function (seat) {
            const seatOb = DrawSeatMapsService.getSeat(seat.id);
            // d3.select("ss").empty()
            if (seatOb.empty()) {
                return;
            }
            if (select) {
                seatOb.classed('reserve-temp', false);
            }
            const color = seatOb.datum().color;
            const circle = seatOb.select('circle');
            //  console.log(color)
            circle.style('stroke-width', '0.2');
            if (color) {
                circle.style('fill', color);
            }
        });
    }

    drawSeatMap(val?: boolean) {
        //return
        /// console.log('venus ###############', this.venus);
        //  console.log('d3 ###############', d3);
        if (
            this.venue &&
            this.venue.levels &&
            this.venue.levels.length > 0 &&
            d3
        ) {
            if (val) {
                this.venue.levels.forEach(function (level) {
                    level.sections.forEach(function (section) {
                        const lev = DrawSeatMapsService.getGroup(
                            'level',
                            level.id,
                        );
                        DrawSeatMapsService.drawSectionPolygons(
                            lev,
                            section,
                            level,
                        );
                    });
                });
            } else {
                this.seatSizeFactor =
                    this.venue.seatSizeFactor || this.seatSizeFactor;
                this.scaleFactor = 10;

                //    console.log('****************************************************')
                DrawSeatMapsService.setSeatSizeFactor(this.seatSizeFactor);
                //console.log(this.venue.numberOfSeats);
                if (this.seatMapService.smallSeatsMap) {
                    DrawSeatsmapSaleComponent._smallSeatMap = true;
                    DrawSeatMapsService.drawVenue(this.venue);
                    DrawSeatsmapSaleComponent.watchStartSeatsManager.next(
                        new Date().getTime(),
                    );
                    this.updateSeatWithPrice();
                } else {
                    DrawSeatsmapSaleComponent._smallSeatMap = false;
                    DrawSeatMapsService.drawVenuePolygon(
                        this.venue,
                        this.eventId,
                    );
                }
                setTimeout(() => {
                    this.doCenterSeatsMap();
                }, 1000);
            }
            this.venue?.priceLevels?.forEach((value) => {
                let max = 0;
                let min = 1000000;
                value.prices.forEach((value1) => {
                    if (max < value1.price) {
                        max = value1.price;
                    }
                    if (min > value1.price) {
                        min = value1.price;
                    }
                });
                value.max = max;
                value.min = min;
            });
        }
    }

    clearSeats() {
        this.removeStyleForHoverOrSelectSeat(this.seatsHover, true);
        this.seatsHover = null;
        this.onSeatsSelected = false;
        this.seatSelected = <SeatsSelected>{};
    }

    actionControlZoom($event: any) {
        //console.log($event);
        if ($event.action === 'zoomHome') {
            DrawSeatMapsService.removeAllSeatsReset();
        }
    }

    hovers(seatsSelected: SeatsSelected, strokeWidth = '1') {
        seatsSelected.data.forEach(function (seat) {
            const seatSvg = DrawSeatMapsService.getSeat(seat.id);
            const circle = seatSvg.select('circle');
            circle.style('stroke-width', strokeWidth);
        });
    }

    clearTooltip() {
        const a = document.getElementById('tkp-tooltip');
        //  a.style.display="none"
    }

    activeTooltip() {
        const a = document.getElementById('tkp-tooltip');
        // a.style.display="block"
    }
}
