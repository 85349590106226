<lib-page-load [website]="websiteSaleService.getLocalConfig()"></lib-page-load>
<div class="theme-wrapper">
  <lib-message></lib-message>
  @if (showApp) {
    <router-outlet ></router-outlet>
  }
  @if (!showApp && response) {
    <tw-page-not-found [message]="errorPage"></tw-page-not-found>
  }
</div>


