import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PipeModule } from '../../../pipe/pipe.module';

@Component({
    selector: 'lib-password-error-msg',
    standalone: true,
    imports: [CommonModule, TranslateModule, PipeModule],
    templateUrl: './password-error-msg.component.html',
    styleUrl: './password-error-msg.component.css',
})
export class PasswordErrorMsgComponent {
    @Input() password: any;
    constructor(public translateService: TranslateService,) {
    }
}
