import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlZoomComponent } from './control-zoom.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCard } from '@angular/material/card';


@NgModule({
    declarations: [ControlZoomComponent],
    exports: [ControlZoomComponent],
    imports: [CommonModule, MatIconModule, MatCard],
})
export class ControlZoomModule {}
