import {Component, Input, OnInit} from '@angular/core';
import { CartPrice } from '../../../../../projects/shared-lib/src/lib/models/cart-price';

@Component({
    selector: 'tw-cart-detail',
    templateUrl: './cart-detail.component.html',
    styleUrls: ['./cart-detail.component.css']
})
export class CartDetailComponent implements OnInit {

    @Input() cartPrices: any |CartPrice[];
    @Input() location: any;

    constructor() {
    }

    ngOnInit() {
    }

}
