import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import { ClientService } from '../../client.service';
import { NgForm } from '@angular/forms';
import { WebsiteSaleService } from '../../../shared/services/website-sale.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MessageAction, MessageObserverService } from '../../../../../projects/shared-lib/src/lib/services/message-observer.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { SocialUser } from '../../../shared/sociallogin/entities';
import { FacebookLoginProvider, GoogleLoginProvider, LinkedInLoginProvider } from '../../../shared/sociallogin/providers';
import { FacebookUser } from '../../../models/facebook-user';
import { GoogleUser } from '../../../models/google-user';
import { CartService } from '../../../shared/services/cart.service';
import { Cart } from '../../../models/cart';
import {ClientDetails} from '../../../models/client-details';
import {SymplifyCartService} from '../../../shared/services/symplify-cart.service';


@Component({
    selector: 'tw-login-client',
    templateUrl: './login-client.component.html',
    styleUrls: ['./login-client.component.css']
})
export class LoginClientComponent implements OnInit {
    load = true;
    isPageLogin = false;
    user: SocialUser;
    isCheckoutPage = false;
    @Output() wacthLogin = new EventEmitter<any>();
    client: ClientDetails;


    constructor(public clientService: ClientService,
                public cartService: CartService,
                private websiteSaleService: WebsiteSaleService,
                public translate: TranslateService,
                private router: Router, private messageObserverService: MessageObserverService,
                private analyticsService: AnalyticsService,
                public symplifyCartService:SymplifyCartService
    ) {


    }

    ngOnInit() {
        this.isPageLogin = this.router.url.search('login') !== -1;
        this.isCheckoutPage = !(this.router.url.search('mode') === -1) || !(this.router.url.search('checkout') === -1);
        this.analyticsService.trackLoginView();

    }

    async authClient(user: NgForm) {
        this.load = false;
        const account = this.websiteSaleService.getLocalConfig();
        const us = user.value;
        us.accountId = account.accountId;
        try {
            this.load = false;
            const  clientDetail = await this.clientService.loginClientAsync(us)
            this.load = true;
            // this.sessionLocaleService.putSync('cl', value.id, true);
            if (this.router.url.search('checkout/login') !== -1) {
                //console.log('-------');
                const cart: Cart = this.cartService.cartSub.value;
                if (cart && cart.totalQty > 0) {
                    this.router.navigate([this.translate.currentLang + '/checkout/mode'])
                        .then(value => {
                            this.load = true;
                        }).catch(error => {
                        this.load = true;
                    });

                    return;
                } else {
                    this.router.navigate([this.translate.currentLang + '/pages'])
                        .then(value => {
                            this.load = true;
                        }).catch(error => {
                        this.load = true;
                    });

                    return;
                }

            }

            if (this.isCheckoutPage) {
                this.wacthLogin.emit(clientDetail);
                return;
            }

            if (clientDetail.mustChangePassword) {
                this.router.navigate([this.translate.currentLang + '/client/auth/change-password'])
                    .then(value => {
                        this.load = true;
                    }).catch(error => {
                    this.load = true;
                });

            } else
            if (this.isPageLogin) {
                this.router.navigate([this.translate.currentLang + '/client/auth/profile'])
                    .then(value => {
                        this.load = true;
                    }).catch(error => {
                    this.load = true;
                });
            } else {
                this.router.navigate([this.translate.currentLang + '/client/middlewareClient'])
                    .then(value => {
                        this.load = true;
                    }).catch(error => {
                    this.load = true;
                });
            }
        }catch (error1) {

            this.load = true;
            //console.log(error1);
            this.load = true;
            this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: error1.error.error.message });
        }

    }


    signInWithGoogle(): void {
        this.clientService.authServiceSocial.signIn(GoogleLoginProvider.PROVIDER_ID)
            .then(user => {
                //console.log(user)
                const googleUser = <GoogleUser>{
                    idToken: user.idToken,
                    userID: user.id,
                    email: user.email,
                    first_name: user.firstName,
                    last_name: user.lastName

                };
                this.clientService.connectGoogle(googleUser).subscribe(value => {
                    if (this.isPageLogin) {
                        this.router.navigate([this.translate.currentLang + '/client/auth/profile'])
                            .then(value => {
                                this.load = true;
                            }).catch(error => {
                            this.load = true;
                        });
                    } else {
                        this.router.navigate([this.translate.currentLang + '/client/middlewareClient'])
                            .then(value => {
                                this.load = true;
                            }).catch(error => {
                            this.load = true;
                        });
                    }

                }, error1 => {
                    this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: error1.error.error.message });
                });


            }).catch(reason => {
            this.messageObserverService.addMessage(<MessageAction>{ dialog: true, translate: true, message: 'social.googleFailure' }
            );

        });
    }

    signInWithFB(): void {
        this.clientService.authServiceSocial.signIn(FacebookLoginProvider.PROVIDER_ID)
            .then(user => {
                //console.log(user)
                if (user && user.facebookAuthResponse) {
                    const facebookUser = <FacebookUser>{
                        userID: user.id,
                        email: user.email,
                        first_name: user.firstName,
                        last_name: user.lastName,
                        signedRequest: user.facebookAuthResponse.signedRequest
                    };
                    this.clientService.connectFacebook(facebookUser, 'signInWithFB').subscribe(value => {
                        if (this.isPageLogin) {
                            this.router.navigate([this.translate.currentLang + '/client/auth/profile'])
                                .then(value => {
                                    this.load = true;
                                }).catch(error => {
                                this.load = true;
                            });
                        } else {
                            this.router.navigate([this.translate.currentLang + '/client/middlewareClient'])
                                .then(value => {
                                    this.load = true;
                                }).catch(error => {
                                this.load = true;
                            });
                        }
                    }, error1 => {
                        this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: error1.error.error.message });
                    });
                } else {
                    throw 'error connect facebook';
                }
            }).catch(reason => {
            //console.log(reason)
            this.messageObserverService.addMessage(<MessageAction>{ dialog: true, translate: true, message: 'social.facebookFailure' });
        });
    }

    signInWithLinkedIn(): void {

        this.clientService.authServiceSocial.signIn(LinkedInLoginProvider.PROVIDER_ID)
            .then(value => {
                //console.log(value)

            }).catch(reason => {
            //console.log(reason)
        });
    }

    signOut(): void {
        this.clientService.authServiceSocial.signOut()
            .then(value => {
                // console.log(value)

            }).catch(reason => {
            // console.log(reason)
        });
    }


    goto(clientForgotPassword: string) {
        this.router.navigate([ this.translate.currentLang +'/client/'+ clientForgotPassword],{queryParamsHandling: 'merge'})
    }
}
