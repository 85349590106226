import { HttpClient, HttpClientJsonpModule, HttpClientModule  } from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing/app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from '../modules/shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {APP_BASE_HREF, DatePipe} from '@angular/common';
import {environment} from '../../environments/environment';
import {MaterialModule} from '../modules/material';
import {CartModule} from '../modules/cart/cart.module';
import {MainAppComponent} from './main-app/main-app.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {LangChangeEvent, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {AnalyticsService} from '../modules/shared/services/analytics.service';
import {SHARED_INTERCEPTORS_SALE} from '../modules/shared/interceptors';
import {CookieService} from 'ngx-cookie-service';
import {TkpTemplateComponent} from './tkp-template/tkp-template.component';
import {MatCarouselModule} from '../../projects/shared-lib/src/lib/component/carousel/carousel.module';
import {ClientModule} from '../modules/client/client.module';
import { ButtonFloatingOrderModule } from '../modules/button-floating-order/button-floating-order.module';
import {LocationWebModule} from '../modules/location-web/location-web.module';

import { FilterPagesEventsComponent } from './filter-pages-events/filter-pages-events.component';
import {PageLoadModule} from '../../projects/shared-lib/src/lib/component/page-load/page-load.module';
import {DateRangeSelectAdvancedModule} from '../../projects/shared-lib/src/lib/component/date-range-select-advanced/date-range-select-avanced.module';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';
import { MessageModule } from '../../projects/shared-lib/src/lib/component';
const console = ((oldCons) => {

    return {
        log: (message?: any, ...optionalParams: any[]) => {
            if (!environment.production) {
                oldCons.trace(message, optionalParams);
                //oldCons.log(message, optionalParams);

            }

        },
        info: function(text) {
            if (!environment.production) {
                oldCons.info(text);
            }

        },
        warn: function(text) {
            if (!environment.production) {
                oldCons.warn(text);
            }
        },
        error: function(text) {
            if (!environment.production) {
                oldCons.error(text);
            }
        },
        trace: function(text) {
            if (!environment.production) {
                oldCons.trace(text);
            }
        }
    };
});


if (environment.production) {
    //Then redefine the old console
    // @ts-ignore
    // noinspection JSConstantReassignment
    window.console = console(window.console);
}


export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainAppComponent,
        TkpTemplateComponent,
        FilterPagesEventsComponent,

    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MessageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AppRoutingModule,
        SharedModule.forRoot(),
        CartModule,
        MaterialModule,
        MatCarouselModule,
        ClientModule,
        ButtonFloatingOrderModule,
        LocationWebModule,
        DateRangeSelectAdvancedModule,
        PageLoadModule,




    ],
    providers: [{ provide: APP_BASE_HREF, useValue: environment.basePath }, ...SHARED_INTERCEPTORS_SALE, CookieService, DatePipe,
        // Moment can be provided globally to your app by adding `provideMomentDateAdapter`
        // to your app config. We provide it at the component level here, due to limitations
        // of our example generation script.
        provideMomentDateAdapter()],
    bootstrap: [AppComponent],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
    constructor(public translate: TranslateService,
                private analyticsService: AnalyticsService) {

        translate.addLangs(['en', 'fr']);
        translate.setDefaultLang('fr');

        this.analyticsService.getSystemGtms().subscribe((gtmTrackers) => {
            gtmTrackers.forEach((tracker) => {
                window['dataLayer'] = window['dataLayer'] || [];
                window['dataLayer'].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                });
                const f = document.getElementsByTagName('script')[0],
                    j = document.createElement('script');
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + tracker;
                f.parentNode.insertBefore(j, f);
            });
        });

        translate.onLangChange.subscribe((event: LangChangeEvent) => {
            // do something
            //console.log('ljjjlk');
        });
    }
}
