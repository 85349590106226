import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {WebsiteSaleService} from '../modules/shared/services/website-sale.service';
import {TranslateService} from '@ngx-translate/core';
import {SessionLocaleService} from '../modules/shared/services/session-locale.service';
import {Location} from '@angular/common';
import {LangsObserverService} from '../modules/shared/services/langs-observer.service';
import {DateAdapter} from '@angular/material/core';


@Injectable({
    providedIn: 'root'
})
export class AppResolveService  {

    constructor(private websiteSaleService: WebsiteSaleService,
                private router: Router,
                public translate: TranslateService,
                private sessionLocaleService: SessionLocaleService,
                private langsObserverService: LangsObserverService,
                public l: Location,  private _adapter: DateAdapter<any>,) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        const lang = route.paramMap.get('lang');
        return this.resultResolve(lang)
    }
    async resultResolve(lang){
        let langs;
        if (!this.websiteSaleService.configWebsite) {
            await this.websiteSaleService.getConfigWeb()
        }
        langs = this.websiteSaleService.configWebsite.languages;
        this.changeLang(lang, langs);
        return 'ok'
    }

    private changeLang(lang, langs: any[]) {
        console.log(    this.translate.getBrowserLang())
        if (lang !== 'fr' && lang !== 'en' &&  langs && langs.length === 1) {
            if (langs.find(l => l.toLocaleLowerCase() === 'fr')) {
                this.setLang('fr');
                this.router.navigate(['fr/pages'], {queryParamsHandling: 'merge'});
                return;
            }
           this.setLang('en');
            this.router.navigate(['en/pages'], {queryParamsHandling: 'merge'});
        }
        if (lang !== 'fr' && lang !== 'en') {
            if (langs.find(l => l.toLocaleLowerCase() === 'fr') && this.translate.getBrowserLang() === 'fr'){
                this.translate.use('fr');
                this.router.navigate(['fr/pages'], {queryParamsHandling: 'merge'});
                return;
            }
            this.setLang('en');
            this.router.navigate(['en/pages'], {queryParamsHandling: 'merge'});
            return;
        }
        if (langs && langs.length === 1 && langs.find(l => l.toLocaleLowerCase() === 'fr')) {
            this.setLang('fr');
            this.langsObserverService.addLang(lang);
            return;
        }

        this.setLang(lang);
        this.langsObserverService.addLang(lang);
    }
    setLang(val){
        this.translate.use(val)
        this._adapter.setLocale(val)
    }
}


