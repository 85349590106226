import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    MessageAction,
    MessageObserverService,
} from '../../../services/message-observer.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'tw-dialog-msg',
    templateUrl: './dialog-msg.component.html',
    styleUrls: ['./dialog-msg.component.css'],
})
export class DialogMsgComponent implements OnInit, OnDestroy {
    error = [];
    sub = [];

    constructor(

        public dialogRef: MatDialogRef<DialogMsgComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: MessageAction,
        public messageObserverService: MessageObserverService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.error = [];
        const a = this.messageObserverService
            .watchMessage()
            .subscribe((value) => {
                console.log(value.message)
                if (
                    !value || !value.dialog ||
                    this.error?.find(
                        (e) => JSON.stringify(e) === JSON.stringify(value),
                    )
                ) {
                    return;
                }
                this.data=null
                this.error.push(value);
                this.data = value;
            });
        this.sub.push(a);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.sub.forEach((s) => s.unsubscribe());
    }
    get message(){
        return this.translate.instant(this.data.message)
    }
}
