import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageComponent } from './message.component';
import { DialogMsgComponent } from './dialog-msg/dialog-msg.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {  MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';
import { locale as fr } from '../../i18n/fr';
import { locale as en } from '../../i18n/en';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';




@NgModule({
    declarations: [MessageComponent, DialogMsgComponent, SnackBarComponent],
    exports: [MessageComponent, DialogMsgComponent, SnackBarComponent],
    imports: [
        CommonModule, FormsModule, TranslateModule.forChild(), MatDialogModule, MatSnackBarModule, MatToolbarModule, MatButtonModule
    ]
})
export class MessageModule {
    constructor(public translate: TranslateService) {
        // this.translate.setDefaultLang('en');
        // this.translate.setTranslation('fr', fr, true);
        // this.translate.setTranslation('en', en, true);

    }
}
