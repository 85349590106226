import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, lastValueFrom, Observable, of, Subject} from 'rxjs';
import {SessionLocaleService} from './session-locale.service';
import {map} from 'rxjs/operators';
import {RefreshTokenResponse} from '../models/refresh-token-response';
import { ProducerReport } from '../../../../app-report/src/model/producer-report';


@Injectable({
    providedIn: 'root'
})
export class AuthenticationUserService {
    private subjectRqt = new Subject<any>();

    accountSub = new BehaviorSubject<any>(null);
    producerSub = new BehaviorSubject<any>(null);
    expirationRefreshTokenSub = new BehaviorSubject<any>(null);
    constructor(private http: HttpClient,
                private sessionLocaleService: SessionLocaleService) {
    }

    watchActionAuth(): Observable<any> {
        return this.subjectRqt.asObservable();
    }

    loginUser(body: any, httpParams ?: HttpParams): Observable<any> {
       let oauthType: any;
        if(body.oauthType){
           oauthType = body.oauthType
       }
        return this.http.post<any>('v2/refresh-tokens', body, {params: httpParams}).pipe(
            map(value => {
                this.addSessionRefreshToken({...value,oauthType});

            })
        );
    }
    async getRefreshTokensApi(isSwt?){
         const rf = this.getSessionRefreshToken()
         const value = await lastValueFrom(this.http.get<any>('v2/refresh-tokens/'+rf.refreshToken))
         if(isSwt){
             value.isSwt = true
         }
        this.addSessionRefreshToken(value);
         return value;
    }

    getUser(idUser: any, httpParams ?: HttpParams): Observable<any> {
        return this.http.get<any>('v2/users/' + idUser, {params: httpParams}).pipe(
            map(value => {
                SessionLocaleService.putSync('user', value, true);
                this.subjectRqt.next('ok');
                return value
            })
        );
    }

    logger(body: any, severity = 'info', httpParams ?: HttpParams): Observable<any> {
        return this.http.post<any>('v1/log/' + severity, body, {params: httpParams});

    }

    getUserFromSession() {
        return SessionLocaleService.getSync('user', true);
    }

    getUserId() {
        const ref = this.getSessionRefreshToken();
        if (ref && ref.userId) {
            return ref.userId;
        }
        return null;
    }

    accounts(): Observable<any> {
        return this.http.get<any>('v1/accounts');
    }

    logoutUser(): Observable<any> {
        return of(this.deleteSessionRefreshToken());
    }

    addSessionRefreshToken(val) {
        SessionLocaleService.putSync('rf', val, true);
      //  this.subjectRqt.next('ok');
    }
    async setDataFromParamRefreshToken(token) {
        this.addSessionRefreshToken(token);
        const val =await this.getRefreshTokensApi();
        console.log("%%%%%%%%%%%%%%%%%%%%%%%%%5",val);
        const ref= this.getSessionRefreshToken()
        const userTemp =await lastValueFrom(this.getUser(ref.userId));
        await lastValueFrom(this.getProducerHttp());
        this.addAccountSelected({
            code: userTemp.account
        })
    }

    deleteSessionRefreshToken() {
        SessionLocaleService.deleteByKey('rf', true);
        SessionLocaleService.deleteByKey('per', true);
        SessionLocaleService.deleteByKey('user', true);
        SessionLocaleService.deleteByKey('sessionToken', true);
        this.removeProducer()
        this.deleteAccountSelected();

        this.subjectRqt.next(new Date().getTime());
        return true;
    }

    getSessionRefreshToken(): RefreshTokenResponse {
        return SessionLocaleService.getSync('rf', true);
    }

    isExpirationRefreshToken(): boolean {
        const ref = this.getSessionRefreshToken();
        if (!ref) {
            return true;
        }
        if (ref && ref.expiration) {
            const now = new Date();
            const expiration = new Date(ref.expiration);
           if((expiration.getTime() <= now.getTime())){
               this.expirationRefreshTokenSub.next({
                   value:true,timeOut:expiration.getTime() - now.getTime()
               })
            }
            return expiration.getTime() <= now.getTime();

        }
        return true;
    }
    isPresentExpirationDateRefreshToken(){
        const ref = this.getSessionRefreshToken();

        return !!(ref && ref.expiration);

    }
    hasAuthType(){
        const ref = this.getSessionRefreshToken();
        return !!(ref && ref.oauthType);

    }

    addAccountSelected(account) {
        SessionLocaleService.putSync('accountSelected', account, true);
        this.accountSub.next(account);
    }

    getAccountSelected() {
        return SessionLocaleService.getSync('accountSelected', true);
    }

    deleteAccountSelected() {
        SessionLocaleService.deleteByKey('accountSelected', true);
        this.accountSub.next(null);
    }

    getAccessToken(): Observable<any> {
        const ref = this.getSessionRefreshToken();
        return this.http.post<any>(`v1/refresh-tokens/${ref.refreshToken}/actions/authorize`, {});
    }
    addProducer(val){
        SessionLocaleService.putSync('producer',val,true)
        this.producerSub.next(val)
    }
    getProducer(){
        return SessionLocaleService.getSync('producer',true)
    }
    removeProducer(){
        SessionLocaleService.deleteByKey('producer',true)
        this.producerSub.next(null)
    }

    getProducerHttp( httpParams ?: HttpParams): Observable<ProducerReport[]> {
        const user = this.getUserFromSession();
        return this.http.get<ProducerReport[]>('v1/reports/producers', {params: httpParams}).pipe(
            map(value => {
                const producerReport =value.find((ser) => ser.id === user.producerId);

                SessionLocaleService.putSync('producer', producerReport, true);
                this.producerSub.next(value)
                return value
            })
        )
    }
}
