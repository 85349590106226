<!--{{data | json}}-->
@if (!data.template) {
  <div class="tw-flex tw-column">
    @if (data.title) {
<div class="tw-flex  tw-row title-snack border-left " style="justify-content: space-between;align-items: center;
padding: 0.8rem;background: #262c38">
        <div class="mat-h1" style="margin: 0">
          {{data.title}}
        </div>
        <button mat-raised-button color="primary" (click)="closeSnack()"  > {{'title.OK' | translate}} </button>
      </div>
    }
    @if (data.message) {
      <div class="tw-margin-15 tw-flex tw-column">
          @for (msg of error; track msg) {
              @if (!msg.translate) {
                  <div [innerHTML]="msg.message"></div>
              }
              @if (msg.translate) {
                  <div [innerHTML]="msg.message  | translate"></div>
              }
          }
      </div>
    }
  </div>
}

@if (data && data.template && data.template === 1 && data.message) {
  <div class="tw-flex tw-column">
    <div [ngClass]="snackBarClass"  class="tw-flex tw-row title-snack" style="justify-content: space-between;align-items: center;
padding: 0.8rem;background: #262c38">
      <div class=" tw-flex tw-column mat-body-2" style="margin-bottom: 0;justify-items: center;align-items: center" >

          @for (msg of error; track msg) {
              @if (!msg.translate) {
                  <div [innerHTML]="msg.message"></div>
              }
              @if (msg.translate) {
                  <div [innerHTML]="msg.message  | translate"></div>
              }
          }


      </div>
      <div style="margin-left: 5px">
        <button mat-stroked-button color="primary" (click)="closeSnack()"  > {{'title.OK' | translate}} </button>
      </div>
    </div>
  </div>
}
