import { InjectionToken, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawSeatsmapSaleComponent } from './draw-seatsmap-sale/draw-seatsmap-sale.component';
import { SeatMapContainerComponent } from './seat-map-container/seat-map-container.component';
import { SeatMapRouteModule } from './routes/seatmap-route.module';

import { MatIconModule } from '@angular/material/icon';
import {  MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import {  MatButtonModule } from '@angular/material/button';
import { BiToolBarPriceLevelComponent } from './bi-tool-bar-price-level/bi-tool-bar-price-level.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PlatformModule } from '@angular/cdk/platform';
import {  MatProgressBarModule } from '@angular/material/progress-bar';
import { Configurations } from './../../models/configurations';
import { ControlZoomModule } from '../control-zoom/control-zoom.module';
import {APP_CONFIG} from '../../utils/utilsShared';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

export let InjectorInstance: Injector;
// export const APP_CONFIG = new InjectionToken<Configurations>('config-environment');

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        PlatformModule,
        ReactiveFormsModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule,
        MatButtonModule,
        TranslateModule,
        MatProgressBarModule,
        ControlZoomModule,
        MatMenuModule,
        MatCardModule,
        MatListModule,
        MatProgressSpinner,
    ],
    declarations: [
        DrawSeatsmapSaleComponent,
        SeatMapContainerComponent,
        BiToolBarPriceLevelComponent,
    ],
    exports: [DrawSeatsmapSaleComponent, SeatMapContainerComponent],
})
export class SeatsMapModule {
    constructor(private injector: Injector) {
        InjectorInstance = this.injector;
    }

    static initializeApp(
        config: Configurations,
    ): ModuleWithProviders<SeatsMapModule> {
        console.log('===========', config);
        return {
            ngModule: SeatsMapModule,
            providers: [{ provide: APP_CONFIG, useValue: config }],
        };
    }
}
