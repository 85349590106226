import { PublicClientApplication } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function getPublicClientApplication() {
    return  new PublicClientApplication({
        auth: {
            clientId: '139f29a6-0541-4f8e-921f-c44d587cf002', // Application (client) ID from the app registration
            authority: 'https://login.microsoftonline.com/common/', // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
    });
}
