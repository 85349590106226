@if (password){
    <div class="valid-error-pasword">
        <h5 style="margin: 0">
            {{''| libTranslateLang:'Your password must contain:':'Votre mot de passe doit contenir :'}}
        </h5>
        <ul
            class="ul-passprd-error"
            style="justify-content: center;font-weight: bold">
<!--            @if (password.value?.length>8){-->
<!--                <li class="isValid" innerHTML="{{'formMsg.errors.minlengthCharacters' | translate:({value:8})}}"></li>-->
<!--            } @else {-->
<!--                <li class="isInvalid" innerHTML="{{'formMsg.errors.minlengthCharacters' |translate:({value:8})}}"></li>-->
<!--            }-->
            <li class="li-elm"

                [ngClass]="password.value?.length>8 ? 'isValid' : 'isInvalid'">
                {{''| libTranslateLang:'At least 8 characters':'Au moins 8 caractères'}}
            </li>
            <li>
                {{ '' | libTranslateLang: 'At least one character from each of the following elements:' : 'Au moins un caractère de chacun des éléments suivants :' }}            </li>

                <ul class="ul-s-error-password">
                    <li
                        class="li-elm"
                        [ngClass]="!password.errors || !password.errors.minOneUppercase  ?'isValid':'isInvalid'" >
                        {{''| libTranslateLang:'Upper case letters (A-Z)':'Lettres majuscules (AZ)'}}
                    </li>
                    <li
                        class="li-elm"
                        [ngClass]="!password.errors || !password.errors.minOneLowerCase?'isValid':'isInvalid'">
                        {{''| libTranslateLang:'Lower case letters (a-z)':'Lettres minuscules (a-z)'}}
                    </li>
                    <li
                        class="li-elm"
                        [ngClass]="!password.errors || (!password.errors.minOnSpecialCharacter
                        && !password.errors.notAcceptSpecialCharacter
                        )?'isValid':'isInvalid'">
                        @if (password.errors && password.errors.notAcceptSpecialCharacter){
                            @if (translateService.currentLang !=='fr'){
                            Caractère non accepté (<span [innerHTML]="password.errors.notAcceptSpecialCharacter"></span> ), seuls les caractères spéciaux suivants sont acceptés : <span class='tw-form-error-car'>!&#64;#$%^&*</span>

                            } @else {
                              Character not accepted (<span [innerHTML]="password.errors.notAcceptSpecialCharacter"></span> ), only the following special characters are accepted: <span class='tw-form-error-car'>!&#64;#$%^&*</span>

                            }

                        }
                        @else {
                            {{''| libTranslateLang:'Special characters (e.g. !@#$%^&*)':'Caractères spéciaux (!@#$%^&*)'}}
                        }

                    </li>

                    <li
                        class="li-elm"
                        [ngClass]="!password.errors || !password.errors.minNumber?'isValid':'isInvalid'">
                        {{''| libTranslateLang:'Numbers (0-9)':'Nombres (0-9)'}}
                    </li>

                </ul>
<!--                @if (!password.errors.minOneUppercase){-->
<!--                    <li class="isValid" innerHTML="{{'formMsg.errors.minOneUppercase' | translate}}"></li>-->
<!--                } @else {-->
<!--                    <li class="isInvalid" innerHTML="{{'formMsg.errors.minOneUppercase' | translate}}"></li>-->
<!--                }-->
<!--                @if (!password.errors?.minOneLowerCase){-->
<!--                    <li class="isValid" innerHTML="{{'formMsg.errors.minOneLowerCase' | translate}}"></li>-->
<!--                } @else {-->
<!--                    <li class="isInvalid" innerHTML="{{'formMsg.errors.minOneLowerCase' | translate}}"></li>-->
<!--                }-->
<!--                @if (!password.errors?.minOnSpecialCharacter){-->
<!--                    <li class="isValid" innerHTML="{{'formMsg.errors.minOnSpecialCharacter' | translate}}"></li>-->
<!--                } @else {-->
<!--                    <li class="isInvalid" innerHTML="{{'formMsg.errors.minOnSpecialCharacter' | translate}}"></li>-->
<!--                }-->
<!--                @if (!password.errors?.minNumber){-->
<!--                    <li class="isValid" innerHTML="{{'formMsg.errors.minNumber' | translate}}"></li>-->
<!--                } @else {-->
<!--                    <li class="isInvalid"  innerHTML="{{'formMsg.errors.minNumber' | translate}}"></li>-->
<!--                }-->


        </ul>

    </div>
}
