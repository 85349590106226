<!--{{cart | json}}-->
@if (smallCart && cart) {
  <div style="margin:0px 12px;cursor: pointer;max-width: 160px" class="cart-small-body">
    <div class="tw-flex tw-row"  (click)="goToCart()">
      <div class="cart-small-body-icon-body" style="width: 100%;padding: 4px">
        <mat-icon  style=" transform: scale(1.1)" class="cart-small-body-icon">shopping_cart</mat-icon>
        <!--            <span style=" transform: scale(1.5)" class="material-symbols-outlined">-->
        <!--shopping_cart-->
      <!--</span>-->
      <div class="icon-cart"></div>
    </div>
    <div class="cart-small-body-qty">
      @if (cart.totalQty===1) {
        <span class="cart-small-body-qty-number" > {{cart.totalQty}}
          <ng-container>{{"card.item" | translate}}</ng-container>
        </span>
      }
      @if (cart.totalQty > 1) {
        <span class="cart-small-body-qty-number" > {{cart.totalQty}}
          <ng-container>{{"card.items" | translate}}</ng-container>
        </span>
      }
    </div>
    @if (cart.totalQty >0) {
      <div class="cart-small-body-timer">
        @if (future) {
          <tw-timer-view
            class="cart-small-body-timer-label"
                style="position: absolute;
                top: 9px;"
            [remainingTime]="cart.remainingTime"
            [smallTime]="smallCart" [reservationDate]="future"
            (offTimer)="timerHold($event)">
          </tw-timer-view>
        }
      </div>
    }
  </div>
  <!--    <div  (click)="goToCart()" class="cart-small-body">-->
  <!--        &lt;!&ndash;        <img src="./assets/resources/svg/online-store.svg" style="height: 47px;" alt="cart" >&ndash;&gt;-->
  <!--        <mat-icon style="font-size: 47px" class="cart-small-body-icon">shopping_basket</mat-icon>-->
<!--        <div class="cart-small-body-qty" style="-->
<!--                            font-size: 22px;-->
<!--                            margin-top: -53px;-->
<!--                            margin-left:59px;-->
<!--                            height: 48px;-->
<!--                            color:green;-->
<!--                            "-->

<!--        > <span class="cart-small-body-qty-span"-->
<!--                style="  text-align: center;font-size: 15px;-->
<!--                            height: 22px;-->
<!--                            width: 22px;-->
<!--                            background-color: white;-->
<!--                            border-radius: 50%;-->
<!--                            display: inline-block;-->
<!--                            border: 1px solid;"-->
<!--                *ngIf="cart.totalQty>=0"-->
<!--        >  {{cart.totalQty}}</span>-->

<!--        </div>-->

<!--    </div>-->
<!--    <div style="text-align: right" *ngIf="cart.totalQty> 0" class="cart-small-body-qty-timer" >-->
<!--        <div *ngIf='refreshCart' class="cart-small-body-qty-timer-2">-->
<!--            <tw-timer-view [smallTime]="smallCart" *ngIf="future" [reservationDate]="future"-->
<!--                           (offTimer)="timerHold($event)"></tw-timer-view>-->

<!--        </div>-->
<!--        <div class="cart-small-body-qty-price">-->
<!--            {{cart.tax.totalAfterTaxes / 100 | currency}}-->
<!--        </div>-->
<!--    </div>-->


</div>
}
@if (!smallCart) {
<div class="tw-padding-10">
    @if (cart) {

        @if (cart &&  cart.cartElements.length === 0) {
<div style="margin: auto;text-align: center">
            <div style="">
                <span>{{ "cart.emptyShipping" | translate }}</span>
            </div>
            <div style="margin-top: 10px">
                <a class="bt-continue-shopping-temp" mat-raised-button color="primary" twClickRedirectUrl>
                    <span (click)="trackContinueShopping()">{{ "cart.continueShopping" | translate }}</span>
                </a>
            </div>
        </div>
}

}

    @if (cart && cart.cartElements.length > 0) {
<div style="max-width: 600px" [ngStyle]="{'margin': (showButton?'auto':'none')}"
        >
        @if (refreshCart) {
<div class="">
            @if (future) {
<tw-timer-view  [remainingTime]="cart.remainingTime" [reservationDate]="future" (offTimer)="timerHold($event)"></tw-timer-view>
}
        </div>
}
        <div style="margin: auto;" [hidden]="hideDetailCart">
            <tw-cart-summary [cartElements]="cart.cartElements" [tax]="cart.tax"
                             [isInCheckout]="isInCheckout"></tw-cart-summary>
        </div>
        <!--        <div style="width: 100%;height: 1px;background: black"></div>-->
        <!--        <mat-divider></mat-divider>-->
        <div  class="tw-flex tw-row ">
          <div style="text-align: left ;width: 100%">
            <button class=" bt-continue-shopping-temp" mat-raised-button color="primary" (click)="goToHome()"
              twClickRedirectUrl>
              @if (translate.currentLang  === 'fr') {
                Continuer à magasiner
              }
              @if (translate.currentLang  !== 'fr') {
                Continue Shopping
              }
            </button>
          </div>
          <!--            <div style="text-align: right" *ngIf="environment.oldCheckout && !pageReservationToken" >-->
          <!--                <button class="tw-primary" mat-raised-button color="primary"-->
          <!--                        (click)="goToCheckout()">{{ "checkout.checkout" | translate }}-->
        <!--                </button>-->
      <!--            </div>-->
      @if (showButton) {
        <div style="text-align: right">
          <button  mat-raised-button color="primary" style="width: max-content"
            (click)="goToNewCheckout()">
            @if (translate.currentLang  === 'fr') {
              Passer à la caisse
            }
            @if (translate.currentLang  !== 'fr') {
              Checkout
            }
          </button>
        </div>
      }
    </div>
  </div>
}
<!--     <tw-ads-pages></tw-ads-pages>-->
</div>
}



