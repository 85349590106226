import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ClientService } from '../../client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { WebsiteSaleService } from '../../../shared/services/website-sale.service';

import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { environment } from '../../../../../environments';
import { MessageAction, MessageObserverService } from '../../../../../projects/shared-lib/src/lib/services';

@Component({
    selector: 'tw-client-signup',
    templateUrl: './client-signup.component.html',
    styleUrls: ['./client-signup.component.css'],
})
export class ClientSignupComponent implements OnInit {
    @Output() wacthSignup = new EventEmitter<any>();

    load = true;
    isCheckoutPage = false;
    email$: string[] = [];
    options = [];
    environment = environment;
    @ViewChild('user', { static: true }) userForm: UntypedFormGroup;
    passwordPattern = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&0-])[A-Za-z_\\d@$!%*?&-]{8,}$',
    );
    isPasswordShowing = false;

    constructor(
        private clientService: ClientService,
        private route: ActivatedRoute,
        private router: Router,
        private websiteSaleService: WebsiteSaleService,
        private messageObserverService: MessageObserverService,
        public translate: TranslateService,
        private analyticsService: AnalyticsService,
    ) {}

    resetConfirmation() {
        this.userForm.controls.cPassword.reset();
    }

    ngOnInit() {
        //this.isCheckoutPage = this.router.url.search('mode') !== -1 || this.router.url.search('checkout') !== -1;
        this.isCheckoutPage = this.router.url.search('mode') !== -1;

        this.analyticsService.trackSignupView();
    }

    signUp(user: NgForm) {
        this.load = false;
        if (user.value.cPassword !== user.value.password) {
            this.messageObserverService.addMessage(<MessageAction>{
                dialog: true,
                message: 'client.passwordsMustBeSame',
                translate: true,
            });
            this.load = true;
            return;
        }
        const account = this.websiteSaleService.getLocalConfig();
        const us = user.value;
        us.accountId = account.accountId;
        this.clientService.createClient(us).subscribe(
            () => {
                this.clientService.getClientSelf().subscribe(
                    (value1) => {
                        this.load = true;

                        if (!this.isCheckoutPage) {
                            this.router
                                .navigate([
                                    this.translate.currentLang +
                                        '/client/auth/profile',
                                ])
                                .then(() => {
                                    this.load = true;
                                })
                                .catch((error) => {
                                    //console.error(error);
                                    this.load = true;
                                    this.messageObserverService.addMessage(<
                                        MessageAction
                                    >{ dialog: true, message: 'error router' });
                                });
                        } else {
                            this.wacthSignup.emit(value1);
                        }
                    },
                    (error1) => {
                        this.load = true;
                        this.messageObserverService.addMessage(<MessageAction>{
                            dialog: true,
                            message: error1.error.error.message,
                        });
                    },
                );
            },
            (error1) => {
                this.load = true;
                this.messageObserverService.addMessage(<MessageAction>{
                    dialog: true,
                    message: error1.error.error.message,
                });
            },
        );
    }
    getEmailList(v) {
        this.email$ = v;
        // console.log(this.email$);
    }
    showPassword() {
        this.isPasswordShowing = !this.isPasswordShowing;
    }
    goto(val: string) {
        this.router.navigate([this.translate.currentLang + '/client/' + val], {
            queryParamsHandling: 'merge',
        });
    }
}
