import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {constSystem} from '../../shared/const-system';
import {Address, EnumAddressType} from '../../models/address';
import {WebsiteSaleService} from '../../shared/services/website-sale.service';
import {TranslateService} from '@ngx-translate/core';

/*
* https://developers.google.com/web/updates/2015/06/checkout-faster-with-autofill
*/

export interface Country {
    country: string;
    province: string;
}

export interface Sta {
    name: string;
    ab: string;
    nameFr: string;
    nameEn: string;
}

export enum UnitTypeEnum {
    APARTMENT = 'APARTMENT',
    SUITE = 'SUITE',
    UNIT = 'UNIT'
}

@Component({
    selector: 'tw-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit, AfterViewInit {


    @Input() formGroupAddress: UntypedFormGroup |any;
    @Input() address = <Address>{};
    @Input() type: EnumAddressType;

    @Output() addressChange = new EventEmitter<Country>();
    country: Sta[];
    statesUS: Sta[];
    statesCAD: Sta[];
    isGuestOnly: boolean;
    unitType = Object.keys(UnitTypeEnum);
    translatedUnitTypes: { key: string, value: string }[] = [];

    constructor(private fb: UntypedFormBuilder, private websiteSaleService: WebsiteSaleService, private translate: TranslateService) {
        this.country = <Sta[]>constSystem.world.countries;
        this.statesUS = <Sta[]>constSystem.world.states;
        this.statesCAD = <Sta[]>constSystem.world.provinces;
        this.isGuestOnly = websiteSaleService.getLocalConfig() === null || websiteSaleService.getLocalConfig().clientAccount === 'NO';
    }

    ngOnInit() {
        this.unitType.forEach(key => {
            this.translate.get(`client.address.unitTypes.${key}`).subscribe((res: string) => {
                this.translatedUnitTypes.push({ key, value: res });
            });
        });

        if (!this.type) {
            this.type = EnumAddressType.BILLING;
        }
        this.formGroupAddress.addControl('addressLine1',
            this.fb.control((this.address && this.address.addressLine1 ? this.address.addressLine1 : ''), Validators.required));
        this.formGroupAddress.addControl('addressLine2',
            this.fb.control((this.address && this.address.addressLine2 ? this.address.addressLine2 : '')));
        this.formGroupAddress.addControl('appartment',
            this.fb.control((this.address && this.address.appartment ? this.address.appartment : '')));
        this.formGroupAddress.addControl('city',
            this.fb.control((this.address && this.address.city ? this.address.city : ''), Validators.required));
        this.formGroupAddress.addControl('zipCode',
            this.fb.control((this.address && this.address.zipCode ? this.address.zipCode : ''), Validators.required));

        this.formGroupAddress.addControl('company',
            this.fb.control((this.address && this.address.company ? this.address.company : '')));

        this.formGroupAddress.addControl('unitType',
            this.fb.control((this.address && this.address.unitType ? this.address.unitType : '')));


        let countryDefault;
        //  console.log(Object.keys(this.address).length);
        if (this.address && Object.keys(this.address).length > 0) {
            countryDefault = this.getAbrCountry(this.address.country);
            this.formGroupAddress.addControl('country',
                this.fb.control(countryDefault, Validators.required));
            this.formGroupAddress.addControl('statesCAD', this.fb.control(this.getAbrProvince(this.address.province)));
            this.formGroupAddress.addControl('statesUS', this.fb.control(this.getAbrProvince(this.address.province, true)));
            this.formGroupAddress.addControl('statesWorld', this.fb.control(this.address.province));


            // if (countryDefault === 'CA') {
            //     this.formGroupAddress.addControl('statesCAD', this.fb.control(this.getAbrProvince(this.address.province)));
            // }
            // if (countryDefault === 'US') {
            //     this.formGroupAddress.addControl('statesUS', this.fb.control(this.getAbrProvince(this.address.province, true)));
            // }
            // if (countryDefault !== 'US' && countryDefault !== 'CA') {
            //     this.formGroupAddress.addControl('statesWorld', this.fb.control(this.address.province, true));
            // }

        } else {
            this.formGroupAddress.addControl('country',
                this.fb.control('', Validators.required));
            this.formGroupAddress.addControl('statesUS', this.fb.control(''));
            this.formGroupAddress.addControl('statesCAD', this.fb.control(''));
            this.formGroupAddress.addControl('statesWorld', this.fb.control(''));
        }


        this.formGroupAddress.controls['statesCAD'].valueChanges.subscribe(val => {
            const county = this.formGroupAddress.controls['country'].value;
            if (county === 'CA') {
                this.addressChange.emit(<Country>{country: county, province: val});
            }

        });
        this.formGroupAddress.controls['statesUS'].valueChanges.subscribe(val => {
            const county = this.formGroupAddress.controls['country'].value;
            if (county === 'US' && val) {
                this.addressChange.emit(<Country>{country: county, province: val});
            }
        });
        this.formGroupAddress.controls['statesWorld'].valueChanges.subscribe(val => {
            const county = this.formGroupAddress.controls['country'].value;
            if (county !== 'US' && county !== 'CA') {
                this.addressChange.emit(<Country>{country: county, province: val});
            }
        });
        this.formGroupAddress.controls['country'].valueChanges.subscribe(val => {
            const province = this.formGroupAddress.controls['statesWorld'].value;
            this.formGroupAddress.removeControl('statesUS');
            this.formGroupAddress.removeControl('statesCAD');

            if (val !== 'US' && val !== 'CA') {
                this.addressChange.emit(<Country>{country: val, province: province});
            }
            if (val === 'US') {
                this.formGroupAddress.addControl('statesUS', this.fb.control(null,Validators.required));
                // this.formGroupAddress.patchValue({
                //     statesUS: 'AL'
                // });

            }
            if (val === 'CA') {
                this.formGroupAddress.addControl('statesCAD', this.fb.control(null,Validators.required));
                // this.formGroupAddress.patchValue({
                //     statesCAD: 'QC'
                // });

            }
        });
        if (!countryDefault) {
            this.formGroupAddress.patchValue({
                country: 'CA'
            });
        }

        this.formGroupAddress.valueChanges.subscribe((value) => {
            //this.convertToAddress(value);
        });

        if (!this.address || !this.address.appartment || this.address.appartment.trim() === '') {
            this.formGroupAddress.get('unitType').disable();
        }

        this.formGroupAddress.get('appartment').valueChanges.subscribe(value => {
            if (!value || value === '') {
                this.formGroupAddress.get('unitType').disable();
                this.formGroupAddress.get('unitType').setValue(null);
            } else {
                this.formGroupAddress.get('unitType').enable();
            }
        });
    }

    convertToAddress(val) {
        if (val) {
            this.address.addressLine1 = val.addressLine1;
            this.address.addressLine2 = val.addressLine2;
            this.address.appartment = val.appartment;
            this.address.city = val.city;
            this.address.zipCode = val.zipCode;
            this.address.country = val.country;
            this.address.type = this.type;
            this.address.company = val.company;
            this.address.unitType = val.unitType
            if (val.country === 'CA') {
                this.address.province = val.statesCAD;
            }
            if (val.country === 'US') {
                this.address.province = val.statesUS;
            }
            if (val.country !== 'US' && val.country !== 'CA') {
                this.address.province = val.statesWorld;
            }
        }
    }

    get countrySelected() {
        return this.formGroupAddress.get('country').value;
    }

    ngAfterViewInit(): void {
        // this.formGroupAddress.patchValue({
        //     country: 'CA'
        // });
        // this.formGroupAddress.patchValue({
        //     statesCAD: 'QC'
        // });
        // this.formGroupAddress.patchValue({
        //     statesUS: 'AL'
        // });
    }

    getAbrCountry(val: string): string {
        if (!val) {
            return 'CA';
        }
        val = val.toLowerCase();
        const temp = constSystem.world.countries.find(value => value.name.toLowerCase() === val || value.ab.toLowerCase() === val);
        if (temp) {
            return temp.ab;
        }
        return '';
    }

    getAbrProvince(val: string, isUsa?: boolean): string {
        let temp: Sta = <Sta>{};
        const statesUS = <Sta[]>constSystem.world.states;
        const statesCAD = <Sta[]>constSystem.world.provinces;
        temp.ab = '';
        if (!val) {
            return temp.ab;
        }
        val = val.toLowerCase();

        if (isUsa) {
            temp = statesUS.find(value =>
                value.nameEn.toLowerCase() === val
                || value.ab.toLowerCase() === val);
        } else {
            temp = statesCAD.find(value =>
                value.nameEn.toLowerCase() === val
                || value.nameFr.toLowerCase() === val
                || value.ab.toLowerCase() === val);
        }
        if (temp) {
            return temp.ab;
        } else {
            return '';
        }

    }

}
