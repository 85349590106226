<div class="seatmap-and-control" >
    @if (load){
        <div  [ngStyle]="{'width':width+'px','height':height+'px'}"
        style="position: absolute;
    width: 785px;
    height: 822px;

    z-index: 2;
    align-items: center;justify-content: center" class="tw-flex "
        >
            <div >
                <mat-spinner ></mat-spinner>
            </div>

        </div>
    }


    <sh-control-zoom
        class="tw-control-zoom"
        svgContainerId="svg-container"
        svgBodyId="chart"
        [width]="width"
        [height]="height"
        right="5"
        (actionControl)="actionControlZoom($event)"

    ></sh-control-zoom>
    <div id="chart" (mouseleave)="clearTooltip()" (mouseenter)="activeTooltip()"
         [ngStyle]="{'width':width+'px' }" style="background: transparent">



    </div>
    <div  *ngIf="positionToolTip" id="tkp-tooltip" [ngStyle]="{top:positionToolTip.y+'px',left:positionToolTip.x+'px'}">


    </div>

</div>
<!--<div style="position: relative">-->
<!--    <div *ngIf="seatSelected.data" style="position: absolute;z-index: 9;height: 100%;background: white;width: 100%" >-->

<!--        <tw-add-seats-to-cart  [detailsSeatsSelected]="seatSelected" (clearSeatSelect)="clearSeats($event)"></tw-add-seats-to-cart>-->
<!--    </div>-->

<!--    <div class="tw-flex tw-row">-->
<!--        <div style="flex-grow: 6" id="chartCt">-->


<!--        </div>-->
<!--        <tw-control-zoom-->
<!--            svgContainerId="svg-container"-->
<!--            [width]="width"-->
<!--            [height]="height"-->

<!--        ></tw-control-zoom>-->
<!--        <div id="chart" [ngStyle]="{'width':width+'px','background-image':'url(./assets/resources/svg/seatmap.png)' }"></div>-->

<!--        <div style="flex-grow: 2">-->
<!--            <tw-bi-tool-bar-price-level   (qtyEmitter)="getQty($event)" [priceLevels]="venue.priceLevels"></tw-bi-tool-bar-price-level>-->

<!--        </div>-->

<!--    </div>-->


<!--</div>-->

