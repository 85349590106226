import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginUserComponent } from './login-user/login-user.component';
import { UserForgotPasswordComponent } from './user-forgot-password/user-forgot-password.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorMessageModule } from '../form-error-message/form-error-message.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { NfcScannerModule } from '../nfc-scanner/nfc-scanner.module';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { MatIconModule } from '@angular/material/icon';

import { MatProgressBarModule } from '@angular/material/progress-bar';
// import {AuthenticationUserGuard} from '../../services/'
import { EqualValidatorDirective } from '../../directives/equal-validator.directive';
import { ShowPaswwordModule } from '../show-paswword/show-paswword.module';
import { DirectivesLibModule } from '../../directives/directives-lib.module';
import { AuthenticationUserGuard } from '../../services/authentication-user.guard';
import { MsalModule } from '@azure/msal-angular';
import { PipeModule } from '../../pipe/pipe.module';
import { getPublicClientApplication } from '../../services/entra-microsoft.service';
import { LibSvgIconResizeDirective } from '../../directives/lib-svg-icon-resize.directive';

const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;
export * from './login-user/login-user.component';
export * from './user-forgot-password/user-forgot-password.component';

export const routesAdmin: Routes = [
    {
        path: '',
        component: LoginUserComponent
    },
    {
        path: 'forget-password',
        component: UserForgotPasswordComponent
    },
    {
        path: 'change-password',
        component: UserChangePasswordComponent,
        canActivate: [AuthenticationUserGuard],
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routesAdmin),
        TranslateModule,
        FormErrorMessageModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        NfcScannerModule,
        MatIconModule,
        MatProgressBarModule,
        ShowPaswwordModule,
        DirectivesLibModule,
        MsalModule.forRoot(getPublicClientApplication(), null, null),
        PipeModule,
        LibSvgIconResizeDirective,
    ],
    declarations: [
        LoginUserComponent,
        UserForgotPasswordComponent,
        UserChangePasswordComponent,
        EqualValidatorDirective,
    ],
    exports: [LoginUserComponent, UserForgotPasswordComponent],
})
export class UsersModule {}
