import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from '@angular/forms';

export interface CartValidation {
    modeCartSupports: any;
}

@Directive({
    selector: '[twCardValidation]',
    providers: [{provide: NG_VALIDATORS, useExisting: CardValidationDirective, multi: true}]
})
export class CardValidationDirective implements Validator {

    constructor() {
    }
    @Input('twCardValidation') cardValidation:any| CartValidation;

    static isVisa(val): boolean {
        if (!val) {
            return false;
        }
        const temp = val.substring(0, 1);
        return val.length === 16 && temp === '4';
    }

    static isAmex(val) {
        if (!val) {
            return false;
        }
        const temp = val.substring(0, 2);
        return val.length === 15 && (temp === '37'|| temp === '34' );
    }

    static isDiscover(val) {
        if (!val) {
            return false;
        }
        const temp = val.substring(0, 1);
        return val.length === 16 && temp === '6';
    }

    static isMasterCard(val) {
        if (!val) {
            return false;
        }
        const temp = val.substring(0, 2);
        return val.length === 16 && (temp === '51' || temp === '52' || temp === '53' || temp === '54' || temp === '55');
    }

    static valid_number_card(value) {
        // accept only digits, dashes or spaces
        if (/[^0-9-\s]+/.test(value)) {
            return false;
        }

        // The Luhn Algorithm. It's so pretty.
        let nCheck = 0;
        let bEven = false;
        value = value.replace(/\D/g, '');

        for (let n = value.length - 1; n >= 0; n--) {
            const cDigit = value.charAt(n);
            let nDigit = parseInt(cDigit, 10);

            if (bEven && ((nDigit *= 2) > 9)) {
                nDigit -= 9;
            }

            nCheck += nDigit;
            bEven = !bEven;
        }
        return (nCheck % 10) === 0;
    }

    static valid_card(value, typePayment) {

        const isVisa = CardValidationDirective.isVisa(value);
        const isMasterCard = CardValidationDirective.isMasterCard(value);
        const isAmex = CardValidationDirective.isAmex(value);
        const isDiscover = CardValidationDirective.isDiscover(value);
        if (!CardValidationDirective.valid_number_card(value)) {
            return false;
        }
        if (typePayment.visa && isVisa) {
            return true;
        }
        if (typePayment.mastercard && isMasterCard) {
            return true;
        }
        if (typePayment.amex && isAmex) {
            return true;
        }
        if (typePayment.discover && isDiscover) {
            return true;
        }

        return false;
    }

    static validateCcNumber(control: AbstractControl, cardValidation: CartValidation): ValidationErrors {
        // Here goes the validation code mentioned earlier
        const temp = CardValidationDirective.valid_card( control.value, cardValidation);
        return !temp ? {'cardValidator': {value: control.value}} : null;
    }

    registerOnValidatorChange(fn: () => void): void {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return CardValidationDirective.validateCcNumber(control, this.cardValidation) ;
    }

}
