import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CartPrice } from '../../../../projects/shared-lib/src/lib/models/cart-price';
import { CurrencyPipe, JsonPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { PriceLevels } from '../../models/price-levels';
import { ItemHour } from '../../models/item-hour';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatDivider } from '@angular/material/divider';
import { PriceManagerService } from '../../page/services/price-manager.service';
import { PriceWeb } from '../../../../projects/shared-lib/src/lib/models/price-web';

@Component({
    selector: 'tw-template-price',
    standalone: true,
    imports: [
        MatTooltip,
        CurrencyPipe,
        TranslateModule,
        NgStyle,
        MatButton,
        MatIcon,
        NgClass,
        MatDivider,
        JsonPipe,
        NgTemplateOutlet,
    ],
    templateUrl: './template-price.component.html',
    styleUrl: './template-price.component.css',
})
export class TemplatePriceComponent implements OnInit, OnDestroy {
    @Input() stylePrice: 'onlyPrice' | 'priceAndLevel' | 'priceAndLevel2' =
        'priceAndLevel';
    @Input() priceLevel: PriceLevels;
    @Input() price: CartPrice | PriceWeb | any;
    @Input() itemHour: ItemHour;
    @Input() showQty: any;
    @Input() useTemplateQty = false;
    @Input() showTotal = true;

    serviceCharge_min: number;
    serviceCharge_max: number;
    showPriceLevelAndPrice = false;

    prices: CartPrice[];
    soldout: any;
    price_min: number;
    hasPromo = false;
    rs = false;
    price_max: number;
    original_price_min: number;
    original_price_max: number;

    constructor(
        public translate: TranslateService,
        private priceManagerService: PriceManagerService,
    ) {}

    getPageId() {
        return '';
    }

    ngOnDestroy(): void {}

    ngOnInit(): void {
        if (this.priceLevel) {
            if (this.priceLevel.prices) {
                this.prices = this.priceLevel.prices;
            }
            this.serviceCharge_min = this.priceLevel.serviceCharge_min;
            this.serviceCharge_max = this.priceLevel.serviceCharge_max;
            this.price_min = this.priceLevel.price_min;
            this.price_max = this.priceLevel.price_max;
            this.original_price_min = this.priceLevel.original_price_min;
            this.original_price_max = this.priceLevel.original_price_max;
            if (
                this.priceLevel.prices.length > 1 ||
                this.itemHour?.priceLevels.length > 1
            ) {
                this.showPriceLevelAndPrice = true;
            }
            this.soldout = this.priceLevel.soldout;
            this.hasPromo = this.priceLevel.hasPromo;
            this.rs = this.priceLevel.rs;
        }
        if (this.itemHour?.priceLevels.length > 0) {
            this.showPriceLevelAndPrice = true;
        }
        if (this.price) {
            this.prices = [this.price];
            this.serviceCharge_min = this.price.serviceCharges;
            this.serviceCharge_max = this.price.serviceCharges;
            this.price_min = this.price.price;
            this.price_max = this.price.price;
            this.soldout = this.price.soldout;
            this.hasPromo = !!this.price.promoId;
            this.rs = this.price.rs;
        }
    }
    qtyMinusOne(price: CartPrice,priceLevel: PriceLevels) {
        if(priceLevel){
            this.priceManagerService.qtyMinusOne(
                this.getFirstPrice(priceLevel, this.itemHour),
            );
            return
        }
        if (this.itemHour) {
            price.date = this.itemHour.dateOnly;
            price.hoursId = this.itemHour.expositionId;
        }
        this.priceManagerService.qtyMinusOne(price);
    }

    qtyPlusOne(price: CartPrice,priceLevel: PriceLevels) {
        if(priceLevel){
            this.priceManagerService.qtyPlusOne(
                this.getFirstPrice(priceLevel, this.itemHour),
            );
            return
         }

        // this.priceDetail.date
        if (this.itemHour) {
            price.date = this.itemHour.dateOnly;
            price.hoursId = this.itemHour.expositionId;
        }
        this.priceManagerService.qtyPlusOne(price);
    }
    getFirstPrice(priceLevel: PriceLevels, itemHour: ItemHour) {
        const p = this.price || priceLevel.prices[0];
        if (this.itemHour) {
            p.date = this.itemHour.dateOnly;
            p.hoursId = this.itemHour?.expositionId;
        }
        /* si affichage cest par un seul prix ou il ya un liste de prices *****/
        if (priceLevel.prices.length === 1 || this.price) {
            p.confirmed = true;
            return p;
        }

        return p;
    }

    protected readonly Object = Object;
}
