@if (showApp && !template) {
  @if (!isWidget) {
    <div
      [ngStyle]="styleBackground" class="main-container main-container-background" id="main-container-app">
      <div class="app-header-level1-container ">
        @if (configWeb.header && !isSwt) {
          <div class="app-header-level1"
            [innerHTML]="configWeb.header | transformHtml">
          </div>
        }
        <!--        <div  class="app-header-level1"-->
        <!--            >-->
        <!--            <header>-->
        <!--                <img class="sq-image" src="https://cdn.laissezpasser.net/sepaq/image/logo-sepaq.png">-->
      <!--            </header>-->
    <!--        </div>-->
    @if (!isSwt) {
      <div class="tw-flex  tw-row-reverse app-header-level2 menu-body">
        @if (configWeb.clientAccount !== 'NO') {
          <div style="flex-grow:0 ;    padding: 6px 0px;" class="menu-container">
            <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu" class="menu-container-btn menu-container-btn-temp tw-primary">
              <mat-icon class="menu-container-btn-icon menu-container-btn-icon-temp">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item twClickRedirectUrl>
                <!--<mat-icon>voicemail</mat-icon>-->
                <!--<img src="./assets/resources/svg/movie-tickets.svg" style="height: 20px;" alt="cartlogo">-->
                <span>{{ 'title.home' | translate }}</span>
              </button>
              <button mat-menu-item (click)="goToRoute('/cart')">
                <!--<mat-icon>voicemail</mat-icon>-->
                <!--<img src="./assets/resources/svg/movie-tickets.svg" style="height: 20px;" alt="cartlogo">-->
                <span>{{ 'title.cart' | translate }}</span>
              </button>
              @if (!loggedIn) {
                <button mat-menu-item (click)="goClientRoute('login')">
                  <!--<mat-icon>perm_identity</mat-icon>-->
                  <span>{{ 'title.login' | translate }}</span>
                </button>
              }
              @if (!loggedIn) {
                <button mat-menu-item (click)="goClientRoute('client-forgot-password')">
                  <!--<mat-icon>perm_identity</mat-icon>-->
                  <span>{{ 'client.forgotPassword' | translate }}</span>
                </button>
              }
              @if (loggedIn) {
                <button mat-menu-item (click)="goClientRoute('profile')">
                  <!--<mat-icon>perm_identity</mat-icon>-->
                  <span>{{ 'title.profile' | translate }}</span>
                </button>
              }
              @if (loggedIn) {
                <button mat-menu-item (click)="goClientRoute('invoices')">
                  <!--<mat-icon>voicemail</mat-icon>-->
                  <!--<img src="./assets/resources/svg/movie-tickets.svg" style="height: 20px;" alt="cartlogo">-->
                  <span>{{ 'title.invoices' | translate }}</span>
                </button>
              }
              @if (loggedIn) {
                <button mat-menu-item (click)="goClientRoute('tickets')">
                  <!--<mat-icon>voicemail</mat-icon>-->
                  <!--<img src="./assets/resources/svg/movie-tickets.svg" style="height: 20px;" alt="cartlogo">-->
                  <span>{{ 'title.myTickets' | translate }}</span>
                </button>
              }
              @if (loggedIn) {
                <button mat-menu-item (click)="goClientRoute('flex-pass-list')">
                  <!--<mat-icon>voicemail</mat-icon>-->
                  <!--<img src="./assets/resources/svg/movie-tickets.svg" style="height: 20px;" alt="cartlogo">-->
                  <span>Flex pass</span>
                </button>
              }
              @if (loggedIn) {
                <button mat-menu-item (click)="goClientRoute('change-password')">
                  <!--<mat-icon>notifications_off</mat-icon>-->
                  <span>{{ 'title.changePassword' | translate }}</span>
                </button>
              }
              @if (loggedIn) {
                <button mat-menu-item (click)="goClientRoute('logout')">
                  <!--<mat-icon>perm_identity</mat-icon>-->
                  <span>{{ 'title.logout' | translate }}</span>
                </button>
              }
            </mat-menu>
          </div>
        }
        @if (configWeb.languages.length> 1) {
          <div style="flex-grow:1;text-align: right;" class="btn-lang">
            <tw-langs [languageSupported]="configWeb.languages"></tw-langs>
          </div>
        }
        @if (showCart) {
          <div class="btn-cart"
                         style="

                        position: absolute;
                        z-index: 1;
                        top: 9px;
                        right: 125px;
                        background: white;
                        border: 1px #00000038 solid;
                       "
            >
            <tw-app-cart [smallCart]="true"></tw-app-cart>
          </div>
        }
      </div>
    }
  </div>
  <div  [ngClass]="configWeb.styleTkp ? 'tw-app-background-tkp ':'tw-app-background-1'"   class="mat-elevation-z2">
    <!--hhh-->
    <div class="app-body">
      <!--<tw-stepper></tw-stepper>-->
      <router-outlet></router-outlet>
    </div>
    <!--        <div twScrollFix>-->
    <!--            ffffffffffffffffff-->
  <!--        </div>-->
  @if (!isSwt) {
    <div class="tw-mobile">
      <tw-cart-desktop [isSummary]="true" style="height: 50vh;overflow: auto;z-index: 2"></tw-cart-desktop>
    </div>
  }
  <!--<div *ngIf="!configWeb.footer " class="app-footer">-->
  <!--<mat-toolbar color="warn">-->
  <!--<span>Missing footer! </span>-->
<!--</mat-toolbar>-->
<!--</div>-->
</div>
@if (configWeb.footer && !isSwt) {
  <div  id="app-footer" >
    <tw-button-floating-order style="max-width: 1200px;
    display: block;
    margin: auto;"
      nameUnique="main-app-footer-button"
    ></tw-button-floating-order>
    <div class="app-footer" [innerHTML]="configWeb.footer | transformHtml">
    </div>
  </div>
}
</div>
}
@if (isWidget) {
  <div class="app-body">
    <!--<tw-stepper></tw-stepper>-->
    <router-outlet></router-outlet>
  </div>
}
}
@if (showApp && template) {
  <tw-tkp-template [loggedIn]="loggedIn" [configWeb]="configWeb"
  [showCart]="showCart"></tw-tkp-template>
}

@if (errorPage) {
  <tw-page-not-found [message]="errorPage"></tw-page-not-found>
}

<!--<div style="display: flex ;background: rgb(0, 102, 179);-->
<!--    color: white;-->
<!--  flex-wrap: wrap;">-->

<!--    <div style="">-->
<!--        <div style="padding: 24px 20px 0px 15px;">-->
<!--            <a href="//www.ticketpro.ca/" title="Billetterie - TICKETPRO.ca Canada"><img-->
<!--                src="https://ticketpro.ca/api/v1/resources/1292925409.jpeg?version=0" width="120"-->
<!--                alt="Billetterie - TICKETPRO.ca Canada"></a>-->
<!--        </div>-->


<!--    </div>-->


<!--</div>-->
