import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { catchError, lastValueFrom, Observable, of } from 'rxjs';
import { AuthenticationUserService } from './authentication-user.service';
import { PermissionUserService } from './permission-user.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { HelpsService } from '../utils/helps.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationUserGuard {
    constructor(
        private authService: AuthenticationUserService,
        private per: PermissionUserService,
        private router: Router,
        private translate: TranslateService,
    ) {}

     canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | Promise<boolean>> {



        return this.per.getPermission().pipe(
            map(async (value) => {
                console.log("#############################3333333333333333333")
                const urlParams = HelpsService.getUrlParams();
                if (urlParams && Object.keys(urlParams).length > 0 && urlParams['refreshToken']) {
                    console.log('urlParams', urlParams);
                     await this.authService.setDataFromParamRefreshToken(urlParams);

                }
                console.log('urlParams', urlParams, this.authService.getSessionRefreshToken());
                if (!value || this.authService.isExpirationRefreshToken()) {
                    this.authService.deleteSessionRefreshToken();
                    this.router.navigate([
                        this.translate.currentLang + '/login',
                    ]);
                    return false;
                } else if (!value) {
                    this.router.navigate([
                        this.translate.currentLang + '/not-authorized',
                    ]);
                    return false;
                }
                return true;
            }),
            catchError((err, caught) => {
                console.error('Errord`````````````````````````` in guard:', err);
                if (this.authService.isExpirationRefreshToken()) {
                    this.authService.deleteSessionRefreshToken();
                    this.router.navigate([
                        this.translate.currentLang + '/login',
                    ]);
                }
                return of(false);
            }),
        );
    }
}
