import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionLocaleService } from './session-locale.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PermissionUserService {
    permissions: PermissionEnum[] = [];
    permissionsAll:PermissionEnum[] = [];
    constructor(private http: HttpClient,
                private sessionLocaleService: SessionLocaleService) {
    }

    getPermission(): Observable<boolean> {

        if (this.permissions.length > 0) {
            console.log('local data');
            return of(true);
        }
        if (!navigator.onLine) {
            this.permissions = SessionLocaleService.getSync('per', true);

            return of(true);
        }
        return this.http.get<PermissionEnum[]>('v1/user-permissions').pipe(
            map(value => {
                this.permissions = value;
                SessionLocaleService.putSync('per', value, true);
                //console.log(value)
                console.log('has log ************************* ');
                return true;
            })
        );
    }

    userRight(Right, per): boolean {
        try {
            const dd = atob(per);
            let s = '';
            for (let i = 0; i < dd.length; i++) {
                let b = parseInt(String(dd.charCodeAt(i)), 10).toString(2);
                while (b.length !== 8) {
                    b = '0' + b;
                }
                s += b.split('').reverse().join('');
            }
            return (s.charAt(Right) === '1');
        } catch (err) {
            return false;
        }
    }

    userRightAccess(permissionEnum: PermissionEnum): boolean {

        return this.permissions.find(value => value === permissionEnum) !== undefined;
    }
    userRightAccessList(permissionEnum: PermissionEnum[]): boolean {
        const t = this.permissions.filter(value => this.userRightAccess(value));
        return t.length > 0;
    }
    godMode(id){
        const a=[
            {
                name:'med',
                id:1152338701
            },
            {
                name:'laurent',
                id:99
            },
            {
                name:'Guislaine',
                id:51815
            }
        ]
        return a.find(value => value.id===id )!==undefined
    }

    clearPermission() {
        this.permissions = [];
        this.permissionsAll = [];

    }
}

export enum PermissionEnum {
    ACHATRAPIDE= 'ACHATRAPIDE',
    ACHATPLAN= 'ACHATPLAN',
    ACHATPRODUITS= 'ACHATPRODUITS',
    ACHATPOSTES= 'ACHATPOSTES',
    AJUSTEMENT= 'AJUSTEMENT',
    IMPRESSIONCLIENT= 'IMPRESSIONCLIENT',
    DEIMPRESSION= 'DEIMPRESSION',
    REIMPRESSION= 'REIMPRESSION',
    REIMPRESSIONLOT= 'REIMPRESSIONLOT',
    IMPRESSIONREPRESENTATION= 'IMPRESSIONREPRESENTATION',
    IMPRESSIONPOSTE= 'IMPRESSIONPOSTE',
    RETOURBILLETSNONIMPRIMES= 'RETOURBILLETSNONIMPRIMES',
    RETOURBILLETSIMPRIMES= 'RETOURBILLETSIMPRIMES',
    CLIENTANONYME= 'CLIENTANONYME',
    EDITCLIENTPASSWORD= 'EDITCLIENTPASSWORD',
    EFFACERTELEPHONE= 'EFFACERTELEPHONE',
    EFFACERADRESSE= 'EFFACERADRESSE',
    MODIFIERBACKLIST= 'MODIFIERBACKLIST',
    MODIFIERSTANDBY= 'MODIFIERSTANDBY',
    MODIFIERVERIFIERCARTECREDIT= 'MODIFIERVERIFIERCARTECREDIT',
    MODIFIERDEMANDEVERIFICATION= 'MODIFIERDEMANDEVERIFICATION',
    MODIFIERISWITHMAILING= 'MODIFIERISWITHMAILING',
    PAIEMENTCOMPTANT= 'PAIEMENTCOMPTANT',
    PAIEMENTCARTECREDIT= 'PAIEMENTCARTECREDIT',
    PAIEMENTCERTIFICATCADEAU= 'PAIEMENTCERTIFICATCADEAU',
    IMPRESSIONAUTOMATIQUE= 'IMPRESSIONAUTOMATIQUE',
    CHANGERLESCOMPTE= 'CHANGERLESCOMPTE',
    ADMIN= 'ADMIN',
    OPTIONSDERETOUR= 'OPTIONSDERETOUR',
    ALLOWCREDITCARDVIEW= 'ALLOWCREDITCARDVIEW',
    PAIEMENTCHEQUE= 'PAIEMENTCHEQUE',
    PAIEMENTFACTURABLE= 'PAIEMENTFACTURABLE',
    PAIEMENTCONSIGNE= 'PAIEMENTCONSIGNE',
    IMPRESSIONANONYME= 'IMPRESSIONANONYME',
    IMPRESSIONADHOC= 'IMPRESSIONADHOC',
    IMPRESSIONLAISSEZPASSER= 'IMPRESSIONLAISSEZPASSER',
    RELEASESEAT= 'RELEASESEAT',
    PAIEMENTCHARGEBACK= 'PAIEMENTCHARGEBACK',
    TRANSFERTFACTURE= 'TRANSFERTFACTURE',
    FUSIONNECLIENTS= 'FUSIONNECLIENTS',
    MODIFIERFACTURABLE= 'MODIFIERFACTURABLE',
    REDEEMFORFAITS= 'REDEEMFORFAITS',
    ACHATMEMBRES= 'ACHATMEMBRES',
    /** TODO rename to SELLSUBSCRIPTION */  /** TODO rename to SELLSUBSCRIPTION */
    ACHATABONNEMENT= 'ACHATABONNEMENT',
    RESERVATION= 'RESERVATION',
    PAIEMENTTPVEXTERNE= 'PAIEMENTTPVEXTERNE',
    SCAN_CAPACITY= 'SCAN_CAPACITY',
//PAIEMENTMC="//PAIEMENTMC",
//PAIEMENTAMEX="//PAIEMENTAMEX",
    CLIENTSSANSADRESSES= 'CLIENTSSANSADRESSES',
    RETOURAUTRESALEGROUP= 'RETOURAUTRESALEGROUP',
    ORDERMANAGEMENT= 'ORDERMANAGEMENT',
    ORDERMANAGEMENTLSALEGROUP= 'ORDERMANAGEMENTLSALEGROUP',
    ORDERMANAGEMENTLACCOUNT= 'ORDERMANAGEMENTLACCOUNT',
    ORDERMANAGEMENTLFULL= 'ORDERMANAGEMENTLFULL',
//PAIEMENTINTERAC="//PAIEMENTINTERAC",
    IMPRESSIONPOSTECLIENT= 'IMPRESSIONPOSTECLIENT',
    CLIENTSSANSTELEPHONE= 'CLIENTSSANSTELEPHONE',
    ACHATCOMBO= 'ACHATCOMBO',
    TRIAGEPHOTOCLIENT= 'TRIAGEPHOTOCLIENT',
    RETOURBILLETSIMPRIMESCASH= 'RETOURBILLETSIMPRIMESCASH',
    RETOURSHOWANNULE= 'RETOURSHOWANNULE',
    RETOURSHOWANNULEIMPRIME= 'RETOURSHOWANNULEIMPRIME',
    RETOURMEMBERSHIP= 'RETOURMEMBERSHIP',
    PRINTTOSCREEN= 'PRINTTOSCREEN',
    RETOURPARTIELABONNEMENT= 'RETOURPARTIELABONNEMENT',
    AUTOLOGIN= 'AUTOLOGIN',
    FLAGGERREMBOURSABLE= 'FLAGGERREMBOURSABLE',
    MODIFYCLIENTTAG= 'MODIFYCLIENTTAG',
    SCAN_GODMODE= 'SCAN_GODMODE',
    IMPRESSIONCLIENTWITHEMAIL= 'IMPRESSIONCLIENTWITHEMAIL',
    VIEW_ALL_MEMBERSHIP_STARS= 'VIEW_ALL_MEMBERSHIP_STARS',
    PAYDEFERRED= 'PAYDEFERRED',
    PAIEMENTTPV= 'PAIEMENTTPV',
    NEWSLETTER_BLACKLIST= 'NEWSLETTER_BLACKLIST',
    MODIFY_CLIENT_EMAIL= 'MODIFY_CLIENT_EMAIL',
    SCAN_DEBUG= 'SCAN_DEBUG',
    CLIENTSAVECEMAIL= 'CLIENTSAVECEMAIL',
    REPORT_WEB_ACCESS= 'REPORT_WEB_ACCESS',
    REPORT_ALL_REPORTS= 'REPORT_ALL_REPORTS',
    REPORT_ALL_SHOWS= 'REPORT_ALL_SHOWS',
    REPORT_LOG_AS_PRODUCER= 'REPORT_LOG_AS_PRODUCER',
    PAIEMENTCOMPTANTUS= 'PAIEMENTCOMPTANTUS',
    MODIFY_CLIENT= 'MODIFY_CLIENT',
    SCAN_BARCODE_IMPORT= 'SCAN_BARCODE_IMPORT',

// ADMIN	// ADMIN
    ADMIN_FULL= 'ADMIN_FULL',
    ADMIN_SHOWS= 'ADMIN_SHOWS',
    ADMIN_PRODUCTS= 'ADMIN_PRODUCTS',
    ADMIN_PRICETABLES= 'ADMIN_PRICETABLES',
    ADMIN_PRICELEVELTEMPLATES= 'ADMIN_PRICELEVELTEMPLATES',
    ADMIN_SEATSTATUSTEMPLATES= 'ADMIN_SEATSTATUSTEMPLATES',
    ADMIN_GATEMPLATES= 'ADMIN_GATEMPLATES',
    ADMIN_PRODUCERS= 'ADMIN_PRODUCERS',
    ADMIN_USERGROUPS= 'ADMIN_USERGROUPS',
    ADMIN_USERS= 'ADMIN_USERS',
    ADMIN_SALEGROUPS= 'ADMIN_SALEGROUPS',
    ADMIN_PAGE= 'ADMIN_PAGE',
    ADMIN_WEBSITE= 'ADMIN_WEBSITE',
    ADMIN_CHANGESEATSTATUS= 'ADMIN_CHANGESEATSTATUS',
    ADMIN_INITIALIZE= 'ADMIN_INITIALIZE',
    ADMIN_TICKETTEMPLATE= 'ADMIN_TICKETTEMPLATE',
    ADMIN_PRODUCTTEMPLATE= 'ADMIN_PRODUCTTEMPLATE',
    ADMIN_PERFRIGHTS= 'ADMIN_PERFRIGHTS',
    ADMIN_SERVICECHARGETABLES= 'ADMIN_SERVICECHARGETABLES',
    ADMIN_OWNERS= 'ADMIN_OWNERS',
    ADMIN_TESTTICKET= 'ADMIN_TESTTICKET',
    ADMIN_VIEWALLPRODUCERS= 'ADMIN_VIEWALLPRODUCERS',
    ADMIN_AFFILIATE= 'ADMIN_AFFILIATE',
    ADMIN_MEMBERS= 'ADMIN_MEMBERS',
    ADMIN_SURVEY= 'ADMIN_SURVEY',
    ADMIN_FACILITYS= 'ADMIN_FACILITYS',
    ADMIN_DISPOSITIONS= 'ADMIN_DISPOSITIONS',
    ADMIN_BYOC= 'ADMIN_BYOC',
    ADMIN_REPORTGROUPS= 'ADMIN_REPORTGROUPS',
    ADMIN_SUBSCRIPTION= 'ADMIN_SUBSCRIPTION',
    ADMIN_EMAILTEMPLATE= 'ADMIN_EMAILTEMPLATE',
    ADMIN_CHANGE_PRICELEVEL= 'ADMIN_CHANGE_PRICELEVEL',
    ADMIN_COMBIS2= 'ADMIN_COMBIS2',
    ADMIN_FLEXPASS= 'ADMIN_FLEXPASS',
    ADMIN_SCAN= 'ADMIN_SCAN',
//ADMIN_ACCOUNTING_CHARTER="140="//ADMIN_ACCOUNTING_CHARTER",140",
    ADMIN_CERBERUS= 'ADMIN_CERBERUS',
    ADMIN_CHANGE_SEATNOTE= 'ADMIN_CHANGE_SEATNOTE',
    ADMIN_MAILINGLIST= 'ADMIN_MAILINGLIST',
    ADMIN_SEATNOTETEMPLATES= 'ADMIN_SEATNOTETEMPLATES',
    ADMIN_IMPORT_BARCODE= 'ADMIN_IMPORT_BARCODE',
    ADMIN_IMPORT_CLIENTS= 'ADMIN_IMPORT_CLIENTS',
    ADMIN_SEND_EMAILTEMPLATES= 'ADMIN_SEND_EMAILTEMPLATES',
    ADMIN_MASS_MAILING= 'ADMIN_MASS_MAILING',
    ADMIN_BARCODE= 'ADMIN_BARCODE',
    ADMIN_WRITE_PROFILE= 'ADMIN_WRITE_PROFILE',

// SYSTEME	// SYSTEME
    SYSTEM_CONFIGINTERNET= 'SYSTEM_CONFIGINTERNET',
    SYSTEM_ACCOUNT= 'SYSTEM_ACCOUNT',
    SYSTEM_GIFTCERTIFICATE= 'SYSTEM_GIFTCERTIFICATE',
    SYSTEM_DOI18N= 'SYSTEM_DOI18N',
    SYSTEM_SCENES= 'SYSTEM_SCENES',
    SYSTEM_SHOWTYPES= 'SYSTEM_SHOWTYPES',
    SYSTEM_PRICELEVEL= 'SYSTEM_PRICELEVEL',
    SYSTEM_SEATSTATUS= 'SYSTEM_SEATSTATUS',
    SYSTEM_DASHBOARD= 'SYSTEM_DASHBOARD',
    SYSTEM_SERVICECHARGETYPES= 'SYSTEM_SERVICECHARGETYPES',
    SYSTEM_CONFIGURATION= 'SYSTEM_CONFIGURATION',
    SYSTEM_STATISTIQUES= 'SYSTEM_STATISTIQUES',
    SYSTEM_REPORTS= 'SYSTEM_REPORTS',
    SYSTEM_REPORTMENUS= 'SYSTEM_REPORTMENUS',
    SYSTEM_REPORTAGREGATS= 'SYSTEM_REPORTAGREGATS',
    SYSTEM_CREDITCARDPROCESSOR= 'SYSTEM_CREDITCARDPROCESSOR',
    SYSTEM_RECEIPTTEMPLATE= 'SYSTEM_RECEIPTTEMPLATE',
    SYSTEM_TICKETSTOCK= 'SYSTEM_TICKETSTOCK',
    SYSTEM_SETTLEMENT= 'SYSTEM_SETTLEMENT',
    SYSTEM_CONTRACT= 'SYSTEM_CONTRACT',
    SYSTEM_GROOVYSUBSCRIBER= 'SYSTEM_GROOVYSUBSCRIBER',
    SYSTEM_TAG= 'SYSTEM_TAG',
    SYSTEM_SETTLEMENT_VOID= 'SYSTEM_SETTLEMENT_VOID',
    SYSTEM_SETTLEMENT_VIEW= 'SYSTEM_SETTLEMENT_VIEW',
    SYSTEM_GROOVYSCRIPT= 'SYSTEM_GROOVYSCRIPT',

// Acess full	// Acess full
    GODMODE= 'GODMODE'


}
