import {Component, OnInit} from '@angular/core';
import {ClientService} from '../../client.service';
import {NgForm} from '@angular/forms';
import {MessageAction, MessageObserverService} from '../../../../../projects/shared-lib/src/lib/services/message-observer.service';;
import { AnalyticsService } from '../../../shared/services/analytics.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';

@Component({
    selector: 'tw-client-forgot-password',
    templateUrl: './client-forgot-password.component.html',
    styleUrls: ['./client-forgot-password.component.css']
})
export class ClientForgotPasswordComponent implements OnInit {
    load = true;

    constructor(private clientService: ClientService,
                private messageObserverService: MessageObserverService,
                private analyticsService: AnalyticsService,
                public translate: TranslateService,
                private router: Router) {
    }

    ngOnInit() {
        this.analyticsService.trackForgotPasswordView();
    }
    resetPws(user: NgForm) {
        this.load = false;
        // @ts-ignore
        this.clientService.sendEmailRestPassword(user.value).subscribe(() => {
           this.load = true;
            this.messageObserverService.addMessage(<MessageAction>{ dialog: false, message: 'success.requestPasswordReset' , translate: true, snackBar: {}});

        }, error1 => {
            this.load = true;
            this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: error1.error.error.message });

        });
    }
    goto(val: string) {
        this.router.navigate([ this.translate.currentLang +'/client/'+ val],{queryParamsHandling: 'merge'})
    }
}
