import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CartElement} from '../../../models/cart-element';
import {Tax} from '../../../models/tax';

interface TimeReserve {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}
@Component({
    selector: 'tw-cart-summary',
    templateUrl: './cart-summary.component.html',
    styleUrls: ['./cart-summary.component.css']
})
export class CartSummaryComponent implements OnInit , OnDestroy {

    @Input() cartElements:any| CartElement[];
    @Input() isInCheckout = false;
    @Input() tax:any| Tax;

    constructor() {
    }

    ngOnInit() {

    }
    ngOnDestroy(): void {

    }
    get showTax(): boolean {
        if (!this.cartElements) {
            return false;
        }
        if (this.cartElements.length === 1) {
           // return false;
        }
        // return this.cart.tax.t &&
        //     (this.cart.tax.tax1 > 0 || this.cart.tax.tax2 > 0 || this.cart.tax.tax2 > 0);
        return true;
    }

}
