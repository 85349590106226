<mat-card class="leaflet-control-container"     >

    <div class="tw-flex tw-column" style="   align-items: center">
        <div>
            <a id="panUp" class="leaflet-control-pan-up" href="#" title="Up">
                <mat-icon class="mat-elevation-z1">keyboard_arrow_up</mat-icon>
            </a>
        </div>
        <div class="tw-flex tw-row" style="width: 100%;
    justify-content: space-between;">
            <div>
                <a id="panLeft" class="leaflet-control-pan-left" href="#" title="Left">
                    <mat-icon class="mat-elevation-z1">keyboard_arrow_left</mat-icon>
                </a>
            </div>
            <div>
                <a id="panRight" class="leaflet-control-pan-right" href="#" title="Right">
                    <mat-icon class="mat-elevation-z1">keyboard_arrow_right</mat-icon>
                </a>
            </div>
        </div>
        <div>
            <a id="panDown" class="leaflet-control-pan-down" href="#" title="Down">
                <mat-icon class="mat-elevation-z1">keyboard_arrow_down</mat-icon>
            </a>
        </div>
    </div>
    <span class="mat-headline-6" style="text-align: center;
    margin: 0;">Zoom</span>
    <div class="tw-flex tw-row" style="justify-content: space-between;">
        <div>
            <a id="zoomOut" class="leaflet-control-zoom-out leaflet-control-btn" href="#" title="Zoom out">    <mat-icon
                class="mat-elevation-z1"
            >remove</mat-icon></a>

        </div>
        <div>
            <a id="zoomHome" class="leaflet-control-zoom-out leaflet-control-btn" href="#" title="Zoom home">
                <mat-icon  >zoom_in_map</mat-icon>
            </a>
        </div>
        <div>
            <a id="zoomIn" class="leaflet-control-zoom-in leaflet-control-btn" href="#" title="Zoom in">
                <mat-icon class="mat-elevation-z1">add</mat-icon>
            </a>

        </div>
    </div>


<!--    <div class="leaflet-top leaflet-left  has-leaflet-pan-control"-->


<!--    >-->
<!--        <div class="leaflet-control-pan leaflet-control">-->
<!--            <div class="leaflet-control-pan-up-wrap mat-elevation-z2 leaflet-control-btn">-->
<!--                <a id="panUp" class="leaflet-control-pan-up" href="#" title="Up">-->
<!--                    <mat-icon>keyboard_arrow_up</mat-icon>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="leaflet-control-pan-left-wrap mat-elevation-z2 leaflet-control-btn">-->
<!--                <a id="panLeft" class="leaflet-control-pan-left" href="#" title="Left">-->
<!--                    <mat-icon>keyboard_arrow_left</mat-icon>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="leaflet-control-pan-right-wrap mat-elevation-z2 leaflet-control-btn">-->
<!--                <a id="panRight" class="leaflet-control-pan-right" href="#" title="Right">-->
<!--                    <mat-icon>keyboard_arrow_right</mat-icon>-->
<!--                </a>-->
<!--            </div>-->
<!--            <div class="leaflet-control-pan-down-wrap mat-elevation-z2 leaflet-control-btn">-->
<!--                <a id="panDown" class="leaflet-control-pan-down" href="#" title="Down">-->
<!--                    <mat-icon>keyboard_arrow_down</mat-icon>-->
<!--                </a>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="leaflet-control-zoom leaflet-bar leaflet-control mat-elevation-z2">-->
<!--            <a id="zoomHome" class="leaflet-control-zoom-out leaflet-control-btn" href="#" title="Zoom home">-->
<!--                <mat-icon >home</mat-icon>-->
<!--            </a>-->
<!--            <a id="zoomIn" class="leaflet-control-zoom-in leaflet-control-btn" href="#" title="Zoom in">+</a>-->
<!--            <a id="zoomOut" class="leaflet-control-zoom-out leaflet-control-btn" href="#" title="Zoom out">-</a>-->
<!--        </div>-->
<!--    </div>-->
    <div [ngStyle]="{'top':top,'right':right}" class="leaflet-top leaflet-right has-leaflet-pan-control"></div>
    <div [ngStyle]="{'bottom':bottom,'left':left}"  class="leaflet-bottom leaflet-left has-leaflet-pan-control"></div>
    <div [ngStyle]="{'bottom':bottom,'right':right}"  class="leaflet-bottom leaflet-right has-leaflet-pan-control"></div>
</mat-card>
