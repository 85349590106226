import { Component, EventEmitter, Input, numberAttribute, OnInit, Output } from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Zoom from 'd3-zoom';
import { ZoomInterface, ZoomService } from './zoom.service';
import * as d3Transition from 'd3-transition';
import { Subject } from 'rxjs';

export enum ControlPan {
    panRightId = 'panRight',
    panLeftId = 'panLeft',
    panUpId = 'panUp',
    panDownId = 'panDown',
    zoomOutId = 'zoomOut',
    zoomInId = 'zoomIn',
    zoomHome = 'zoomHome'
}

@Component({
    selector: 'sh-control-zoom',
    templateUrl: './control-zoom.component.html',
    styleUrls: ['./control-zoom.component.css'],
})
export class ControlZoomComponent implements OnInit {
    @Input() svgContainerId;
    @Input() svgBodyId;
    @Input() height;
    @Input() width;
    @Input() panRightId = 'panRight';
    @Input() panLeftId = 'panLeft';
    @Input() panUpId = 'panUp';
    @Input() panDownId = 'panDown';
    @Input() zoomOutId = 'zoomOut';
    @Input() zoomInId = 'zoomIn';
    @Input() zoomHome = 'zoomHome';
    @Input() zoomActive;
    @Input() top = 0;
    @Input() bottom = 0;
    @Input({ transform: numberAttribute }) right = 0;
    @Input() left = 0;
    @Output() actionControl = new EventEmitter();
    _sub = new Subject();

    constructor() {}

    ngOnInit() {
        setTimeout(() => {
            const panZoom = new MakePanZoomCTRL(
                '#' + this.svgContainerId,
                '#' + this.svgBodyId,
                this.width,
                this.height,
            );
            panZoom.addSub(this._sub);
            d3.selectAll(
                '#' +
                    this.zoomInId +
                    ',#' +
                    this.zoomOutId +
                    ',#' +
                    this.zoomHome,
            ).on('click', function (event) {
                event.preventDefault();
                const id = d3.select(this).attr('id');
                panZoom.zoom(id);
            });

            d3.selectAll(
                '#' +
                    this.panLeftId +
                    ',#' +
                    this.panRightId +
                    ',#' +
                    this.panDownId +
                    ',#' +
                    this.panUpId,
            ).on('click', function (event) {
                event.preventDefault();

                const id = d3.select(this).attr('id');
                panZoom.pan(id);
            });
        }, 2000);
        this._sub.asObservable().subscribe({
            next: (value) => {
                this.actionControl.next(value);
            },
        });
    }
}

export class MakePanZoomCTRL {
    id: any;
    width: any;
    height: any;
    panRightId = 'panRight';
    panLeftId = 'panLeft';
    panUpId = 'panUp';
    panDownId = 'panDown';
    zoomOutId = 'zoomOut';
    zoomInId = 'zoomIn';
    zoomHome = 'zoomHome';

    ps = 120;
    zoomMax = 30;
    // Levels of Zoom Out
    zoomMin = -this.zoomMax; // Levels of Zoom In
    zoomCur = 0;
    // Current Zoom
    offsetX = 0;
    // Current X Offset (Pan)
    offsetY = 0; // Current Y Offset (Pan)
    ps2 = this.zoomMax / 8;
    svgContainerId;
    svgBodyId;
    output: Subject<any>;

    constructor(svgContainerId: any, svgBodyId: any, width: any, height: any) {
        this.svgContainerId = svgContainerId;
        this.svgBodyId = svgBodyId;
        this.width = width;
        this.height = height;
    }

    transform(btnID?: any) {
        if (ZoomService.active) {
            console.log(this.zoomCur);
            const t = ZoomService.zoomCurrent;
            // console.log(t)
            let x = t.x;
            let y = t.y;
            let s = t.k;
            const duration = 1000;

            switch (btnID) {
                case ControlPan.panRightId:
                    x = t.x + this.ps;
                    // d3.select(this.svgBodyId)
                    //     .transition()
                    //     .duration(duration)
                    //     .call(ZoomService.instance.translateBy, this.ps, 0);
                    ZoomService.instance.translateBy(ZoomService.bodySvg,this.ps, 0)
                    break;
                case ControlPan.panLeftId:
                    x = t.x - this.ps;
                    // d3.select(this.svgBodyId)
                    //     .transition()
                    //     .duration(duration)
                    //     .call(ZoomService.instance.translateBy, -this.ps, 0);
                    ZoomService.instance.translateBy(ZoomService.bodySvg,-this.ps, 0)
                    break;
                case ControlPan.panUpId:
                    y = t.y - this.ps;
                    // d3.select(this.svgBodyId)
                    //     .transition()
                    //     .duration(duration)
                    //     .call(ZoomService.instance.translateBy, 0, -this.ps);
                    ZoomService.instance.translateBy(ZoomService.bodySvg,0, -this.ps)
                    break;
                case ControlPan.panDownId:
                    y = t.y + this.ps;
                    // d3.select(this.svgBodyId)
                    //     .transition()
                    //     .duration(duration)
                    //     .call(ZoomService.instance.translateBy, 0, this.ps);
                    ZoomService.instance.translateBy(ZoomService.bodySvg,0, this.ps)
                    break;
                case ControlPan.zoomOutId:
                    s = 0.7;
                    // d3.select(this.svgBodyId)
                    //     .transition()
                    //     .duration(duration)
                    //     .call(ZoomService.instance.scaleBy, s);
                   // ZoomService.watchZoom.next(<ZoomInterface>{ zoomIdentity:ZoomService.instance.scaleBy(d3Zoom.zoomIdentity.scale(s)), action: 'm' });
                    ZoomService.instance.scaleBy(ZoomService.bodySvg,s);

                    break;
                case ControlPan.zoomInId:
                    s = 2;
                    // d3.select(this.svgBodyId)
                    //     .transition()
                    //     .duration(duration)
                    //     .call(ZoomService.instance.scaleBy, s);

                //   ZoomService.watchZoom.next(<ZoomInterface>{ zoomIdentity: d3Zoom.zoomIdentity.scale(s), action: 'm' });
                    ZoomService.instance.scaleBy(ZoomService.bodySvg,s);
                    break;
                case this.zoomHome:
                    x = ZoomService.scaleInit.x;
                    y = ZoomService.scaleInit.y;
                    s = ZoomService.scaleInit.k;
                    this.output.next({ action: this.zoomHome });

                    ZoomService.watchZoom.next(<ZoomInterface>{ zoomIdentity: d3Zoom.zoomIdentity.translate(x, y).scale(s), action: 'm' });

                    // d3.select(this.svgBodyId)
                    //     .transition()
                    //     .call(ZoomService.instance.transform, d3Zoom.zoomIdentity.translate(x, y).scale(s));
                    //

                    //  DrawSeatMapsService.removeAllSeatsReset();
                    break;



            }
            console.log(x, y, s);
            console.log(t);
            const z = <ZoomInterface>{
                action: btnID,
                zoomIdentity: d3Zoom.zoomIdentity.translate(x, y).scale(s)
            };

             //ZoomService.watchZoom.next(z);
           // ZoomService.instance.transform(ZoomService.bodySvg, z.zoomIdentity);


        }
    }


    pan(btnID: any) {
        if (btnID === this.panLeftId) {
            this.offsetX -= this.ps;
        } else if (btnID === this.panRightId) {
            this.offsetX += this.ps;
        } else if (btnID === this.panUpId) {
            this.offsetY -= this.ps;
        } else if (btnID === this.panDownId) {
            this.offsetY += this.ps;
        }
        this.transform(btnID);
    }

    zoom(btnID) {
        //  this.zoomCur = d3Zome.zoomTransform(DrawSeatMapsService.containerSvg.node()).k
        console.log(this.zoomCur);
        if (btnID === this.zoomInId) {
            if (this.zoomCur >= this.zoomMax) {
                return;
            }
            this.zoomCur = this.zoomCur + this.ps2;
        } else if (btnID === this.zoomOutId) {
            if (this.zoomCur <= this.zoomMin) {
                return;
            }
            this.zoomCur = this.zoomCur - this.ps2;
        }
        if (btnID === this.zoomHome) {
            this.zoomCur = 0;
        }
        this.transform(btnID);
    }

    addSub(output) {
        this.output = output;
    }
}
