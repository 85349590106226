import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {CreditCard} from '../../../models/credit-card';
import {WebsiteSaleService} from '../../services/website-sale.service';
import {ClientService} from '../../../client/client.service';
import {ClientDetails} from '../../../models/client-details';
import {TypePayment} from '../../../models/config-website';
import {ShippingModeRest} from '../../../models/shipping-mode-rest';
import {ExactInteracFormResponse} from '../../../models/exact-interac-form-response';
import {SessionRedisService} from '../../services/session-redis.service';
import {CheckoutService} from '../../../checkout/service/checkout.service';
import {TranslateService} from '@ngx-translate/core';
import {SubdomainService} from '../../services/subdomain.service';
import {ApplePayJS} from '../../../../../projects/shared-lib/src/lib/component/apple-pay/apple-pay/schema';
import {CartService} from '../../services/cart.service';
import {lastValueFrom} from 'rxjs';
import {MessageAction, MessageObserverService} from '../../../../../projects/shared-lib/src/lib/services/message-observer.service';

@Component({
    selector: 'tw-card-form',
    templateUrl: './card-form.component.html',
    styleUrls: ['./card-form.component.css']
})
export class CardFormComponent implements OnInit {

    typeModePayment: TypePayment;
    subdomain: String;
    msgValidCard = true;
    @Input() formGroupCard: UntypedFormGroup|any = new UntypedFormGroup({});
    @Input() cart;
    @Input() totalShippingSelected;
    @Input() card = <CreditCard>{};

    @Input() amount = 0;
    @Input() client = <ClientDetails>{};
    @Input() shippingSelected: ShippingModeRest[];
    @Input() totalToPay = 0;
    @Input() hideGift = false;
    @Input() hideGoogleAnAppelPay = false;
    @Output() creditCardInput = new EventEmitter();
    @Output() cardChange = new EventEmitter<any>();
    @Output() sendInteract = new EventEmitter<any>();
    @Output() sendGift = new EventEmitter<any>();
    @Output() sendPaypal = new EventEmitter<any>();
    @Output() googlePay = new EventEmitter<any>();
    @Output() applePay = new EventEmitter<ApplePayJS.ApplePayPayment>();
    @Output() paymentSelected = new EventEmitter<any>();
    giftBarcode: any;

    exactInteracFormResponse: ExactInteracFormResponse;
    //  @ViewChild('InteracFormPost', { read: ViewContainerRef, static: false }) refInteracFormPost: ElementRef;
    @ViewChild('InteracFormPost') refInteracFormPost;

    urlSvg;
    postingInteract = false;

    typeModePaymentLabel = {
        credit: 'credit',
        interac: 'interac',
        paypal: 'paypal',
        googalPay: 'googlePay'
    };
    choicePayment;

    constructor(private fb: UntypedFormBuilder,
                private websiteSaleService: WebsiteSaleService,
                private session: SessionRedisService,
                private clientService: ClientService,
                private checkoutService: CheckoutService,
                public translate: TranslateService,
                private subdomainService: SubdomainService,
                private cartService: CartService,
                private messageObserverService: MessageObserverService) {

        this.subdomain = this.subdomainService.getSubdomain();
    }

    async ngOnInit() {
        if(!this.formGroupCard){
            this.formGroupCard = new UntypedFormGroup({});
        }
        try {
            this.typeModePayment = await lastValueFrom(this.cartService.getPaymentMethods());
        } catch (error) {
            this.messageObserverService.addMessage(<MessageAction>{dialog: true, translate: true, message: 'formMsg.failureProcessing'});
          return
        }

        let nameOnCard = '';
        const client = this.clientService.getClientLocal() || this.client;
        //console.log(client);
        if (client && client.firstname) {
            nameOnCard = client.firstname + ' ' + client.lastname;
        }

        this.formGroupCard.addControl('nameOnCard', this.fb.control(nameOnCard, Validators.required));
        this.formGroupCard.addControl('number', this.fb.control(''));
        this.formGroupCard.addControl('month', this.fb.control('', Validators.required));
        this.formGroupCard.addControl('year', this.fb.control('', Validators.required));
        this.formGroupCard.addControl('cvd', this.fb.control('', Validators.required));
        this.formGroupCard.valueChanges.subscribe(val => {
            this.convertToCard(val);
            this.cardChange.emit({...val, status: this.formGroupCard.status});

        });
        this.formGroupCard.controls['number'].valueChanges.subscribe(val => {
            if (val) {
                if (this.isVisa(val)) {
                    this.urlSvg = './assets/resources/svg/payment/visa.svg';
                    return;
                }
                if (this.isMasterCard(val)) {
                    this.urlSvg = './assets/resources/svg/payment/mastercard.svg';
                    return;
                }
                if (this.isAmex(val)) {
                    this.urlSvg = './assets/resources/svg/payment/amex.svg';
                    return;
                }
                if (this.isDiscover(val)) {
                    this.urlSvg = './assets/resources/svg/payment/discover.svg';
                    return;
                }
            }
            this.urlSvg = null;
        });
        if (this.typeModePayment.exact && this.client && this.client.id) {
            //console.log("ddddddddddddd")
            let amount = this.amount / 100;
            if (this.shippingSelected && this.shippingSelected.length > 0) {
                this.shippingSelected.forEach(value => {
                    amount = amount + (value.price / 100);
                });
            }
            //console.log(amount);
            this.websiteSaleService.getInteracConfig(this.client.id, amount).subscribe(value => {

                /**   /exact-url/redirect/{session}/{accountCode}/{url}/{lang} **/
                this.exactInteracFormResponse = value;
                //environment
                this.exactInteracFormResponse.x_receipt_link_url = this.exactInteracFormResponse.x_receipt_link_url + '/' + this.session.getXSessionLocal();
                this.exactInteracFormResponse.x_receipt_link_url = this.exactInteracFormResponse.x_receipt_link_url + '/' + this.websiteSaleService.getLocalConfig().accountCode;

                this.exactInteracFormResponse.x_receipt_link_url = this.exactInteracFormResponse.x_receipt_link_url + '/' + btoa(location.origin);
                this.exactInteracFormResponse.x_receipt_link_url = this.exactInteracFormResponse.x_receipt_link_url + '/' + this.translate.currentLang;
                // console.log(this.exactInteracFormResponse.x_receipt_link_url);
            });

        }
        this.checkoutService.subInterat.asObservable().subscribe(value => {
            if (value) {
                this.postingInteract = true;

                setTimeout(() => {
                    this.refInteracFormPost.nativeElement.submit();
                }, 500);
            }

        });

        if (!((this.typeModePayment.exact && this.subdomain !== 'villedequebec') || (this.typeModePayment.exact && this.choicePayment === this.typeModePaymentLabel.interac))) {
            this.choicePayment = this.typeModePaymentLabel.credit
        }

    }

    get getYears() {
        const years = [];
        const currentYear = new Date().getFullYear();
        let i = currentYear;
        for (i; i < (currentYear + 10); i++) {
            years.push(i);
        }
        return years;
    }

    get getMonths() {
        const months = [];
        let i;
        for (i = 1; i <= 12; i++) {
            months.push(i);
        }
        return months;
    }

    private convertToCard(val: any) {
        this.card = <CreditCard>val;
        const m = ((val.month + '').length === 1) ? '0' + val.month : val.month;
        const y = (val.year + '').substr(2, 2);
        this.card.expiry = m + y;
        this.card.year = y;
        this.card.month = m;
    }

    isVisa(val): boolean {
        if (!val) {
            return false;
        }
        const temp = val.substring(0, 1);
        var res : boolean = (temp === '4');
        if (res && val.length == 16) {
            this.creditCardInput.emit('visa');
        }
        return res;
    }

    isAmex(val) {
        if (!val) {
            return false;
        }
        const temp = val.substring(0, 2);
        var res : boolean = temp === '37';
        if (res  && val.length == 15) {
            this.creditCardInput.emit('amex');
        }
        return res;
    }

    isDiscover(val) {
        if (!val) {
            return false;
        }
        const temp = val.substring(0, 1);
        var res : boolean = temp === '6';
        if (res  && val.length == 16) {
            this.creditCardInput.emit('discover');
        }
        return res;
    }

    isMasterCard(val) {
        if (!val) {
            return false;
        }
        const temp = val.substring(0, 2);
        var res : boolean =  (temp === '51' || temp === '52' || temp === '53' || temp === '54' || temp === '55');
        if (res && val.length == 16) {
            this.creditCardInput.emit('mastercard');
        }
        return res;
    }

    // ngAfterViewChecked(): void {
    //
    //     if (this.postingInterac && this.refInteracFormPost) {
    //         console.log('######################################################');
    //         //this.refInteracFormPost.nativeElement.submit();
    //     }
    // }

    manageTypePayment() {
        this.paymentSelected.next(this.choicePayment);
    }

    googleSend(paymentData: google.payments.api.PaymentData) {
        this.creditCardInput.emit('googlepay');
        this.googlePay.emit(paymentData);
    }

    appleSend(paymentData: ApplePayJS.ApplePayPayment) {
        this.creditCardInput.emit('applepay');
        this.applePay.emit(paymentData);
    }
}
