import { Component, OnInit, ViewChild } from '@angular/core';
import { ClientService } from '../../client.service';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageAction, MessageObserverService } from '../../../../../projects/shared-lib/src/lib/services/message-observer.service';;
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ClientDetails } from '../../../models/client-details';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'tw-client-change-password',
    templateUrl: './client-change-password.component.html',
    styleUrls: ['./client-change-password.component.css']
})
export class ClientChangePasswordComponent implements OnInit {
    load = true;
    progressLoad = false;
    token: any;
    @ViewChild('clientForm') userForm: UntypedFormGroup;
    client: ClientDetails;
    passwordPattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/)
    constructor(
        private clientService: ClientService,
        private route: ActivatedRoute,
        private analyticsService: AnalyticsService,
        private router: Router,
        public translate: TranslateService,
        public messageObserverService: MessageObserverService
    ) {
    }

    ngOnInit() {
        //console.log(this.messageObserverService);
        this.analyticsService.trackChangePasswordView();
        this.token = this.route.snapshot.params['token'];
        this.client = this.clientService.getClientLocal();
    }

    resetConfirmation() {
        this.userForm.controls.cPassword.reset();
    }

    async changePw(user: NgForm) {
        this.progressLoad = true;
        this.token = this.route.snapshot.params['token'];
        this.client = this.clientService.getClientLocal();
        if (user.value.cPassword !== user.value.newPassword) {
            this.messageObserverService.addMessage(<MessageAction>
                { dialog: true, message: 'client.passwordsMustBeSame', translate: true });
            this.load = true;
            this.progressLoad = false;
            return;
        }

        if (this.token) {
            const body = { ...user.value, token: this.token };

            this.clientService.restPassword(body).subscribe({
                    next: () => {

                        setTimeout(() => {
                            this.router.navigate([this.translate.currentLang + '/client/login'])
                                .then(value => {
                                    this.progressLoad = false;
                                }).catch(error => {
                                this.progressLoad = false;
                            });
                        }, 2000);
                        this.messageObserverService?.addMessage(<MessageAction>{ dialog: true, message: 'success.update', translate: true, snackBar: {} });

                    },
                    error: (error1) => {
                        this.progressLoad = false;
                        this.messageObserverService?.addMessage(<MessageAction>{ dialog: true, message: error1.error.error.message });

                    }
                }
            );
        } else {
            try {
                this.progressLoad = false;
                await this.clientService.changePasswordAsync(user.value, this.client.id)
                this.messageObserverService.addMessage(<MessageAction>{ dialog: false, message: 'success.update', translate: true, snackBar: {} });
                setTimeout(() => {
                    this.router.navigate([this.translate.currentLang + '/client/auth/profile'])
                        .then(value => {
                            this.progressLoad = false;
                        }).catch(error => {
                        this.progressLoad = false;
                    });
                }, 2000);
            }catch (error1) {
                this.progressLoad = false;
                this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: error1.error.error.message });

            }

            // this.clientService.changePassword(user.value, this.client.id).subscribe({
            //         next: () => {
            //             this.progressLoad = false;
            //             this.messageObserverService.addMessage(<MessageAction>{ dialog: false, message: 'success.update', translate: true, snackBar: {} });
            //             setTimeout(() => {
            //                 this.router.navigate([this.translate.currentLang + '/client/auth/profile'])
            //                     .then(value => {
            //                         this.progressLoad = false;
            //                     }).catch(error => {
            //                     this.progressLoad = false;
            //                 });
            //             }, 2000);
            //         },
            //         error: (error1) => {
            //             this.progressLoad = false;
            //             this.messageObserverService.addMessage(<MessageAction>{ dialog: true, message: error1.error.error.message });
            //
            //         }
            //     }
            // );
        }

    }
}
