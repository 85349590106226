import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    Optional,
    ViewEncapsulation,
} from '@angular/core';
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarRef,
} from '@angular/material/snack-bar';
import {
    MessageAction,
    MessageObserverService,
} from '../../../services/message-observer.service';
import { environment } from '../../../../../../../environments/environment';

@Component({
    selector: 'tw-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class SnackBarComponent implements OnInit, OnDestroy {
    error = [];
    sub = [];
    snackBarClass= '';

    constructor(
        @Optional() @Inject(MAT_SNACK_BAR_DATA) public data: MessageAction,
        public snackBarRef: MatSnackBarRef<SnackBarComponent>,
        public messageObserverService: MessageObserverService,
    ) {}

    ngOnInit() {
        this.error = [];
        const a = this.messageObserverService
            .watchMessage()
            .subscribe((value) => {

                if (!value || !value.snackBar ||
                    this.error.find(
                        (e) => JSON.stringify(e) === JSON.stringify(value),
                    )
                ) {
                    return;
                }
                this.data=null
                this.error.push(value);
                this.data = value;
                if( !value.snackBar.panelClass){
                    if(environment.systemValues === 'tkp'){
                        this.snackBarClass = 'border-left border-left-color-500';
                    }
                }



            });
        this.sub.push(a);
    }

    closeSnack() {
        this.snackBarRef.dismiss();
    }

    ngOnDestroy(): void {
        this.sub.forEach((s) => s.unsubscribe());
    }
}
