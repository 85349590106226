import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { SeatsSelected } from '../../models/seats-selected';
import { SyosPriceLevel } from '../../models/syos-price-level';
import { SyosSeatStatus } from '../../models/syos-seat-status';
import { SyosSeatDetail } from '../../models/syos-seat-detail';
import { SyosVenueDetails } from '../../models/syos-venue-details';
import { PriceWeb } from '../../models/price-web';
import { SyosSection } from '../../models/syos-section';
import { SyosSeat } from '../../models/syos-seat';
import { cloneObjectHelps } from '../../utils/utilsShared';
import {AnalyticsService} from '../../../../../../src/modules/shared/services/analytics.service';

export enum TypeApi {
    FULL = 'full',
    POLYGONS = 'polygons',
    DEFAULT = '',
}

@Injectable({
    providedIn: 'root',
})
export class SeatMapService {
    subQtyBySelecte = new BehaviorSubject<number>(1);
    subBestSeat = new Subject<boolean>();
    subSeatSelected = new BehaviorSubject<SeatsSelected[]>(null);
    subOptionMultiSelect = new BehaviorSubject<boolean>(true);
    subValidQtyDemande = new BehaviorSubject<boolean>(false);
    subHideMenuSelectSeatMap = new BehaviorSubject<boolean>(false);
    MIN_SHOW_POLYGON = 4200; //cette option doit retourne par le backend
    smallSeatsMap = true;
    MIN_SHOW_SEATS = 500;
    subSeatSelectedToRemove = new BehaviorSubject<{eventdId:any,seats:SeatsSelected[]}>(null);

    constructor(private http: HttpClient,
                private analyticsService: AnalyticsService) {}

    watchQtySeatMapBySelect(): Observable<number> {
        return this.subQtyBySelecte.asObservable();
    }

    watchSubBestSeatSelect(): Observable<boolean> {
        return this.subBestSeat.asObservable();
    }

    watchSeatSelected(): Observable< SeatsSelected[]> {
        return this.subSeatSelected.asObservable();
    }

    addSubSeatSelected(seatsSelected: SeatsSelected) {
        let list = <SeatsSelected[]>this.subSeatSelected.value;
        if (!list) {
            list = [];
        }
        let seats = JSON.parse(JSON.stringify(seatsSelected))
        list.push(seats);
        this.analyticsService.trackItemSelection(seats.prices)
        this.subSeatSelected.next(list);
    }
    regroupSubSeatSelectedBuyPrice(seatsSelected?:SeatsSelected[]) {
        let list = cloneObjectHelps( <SeatsSelected[]>this.subSeatSelected.value);
        if(seatsSelected){
            list = seatsSelected;

        }
        if (!list) {
            list = [];
        }
        const newLists = [];
        list.forEach((value) => {
            if(newLists.length===0){
                newLists.push(value);
            }else {
                const find =newLists.find(v=>v.priceLevelId===value.priceLevelId
                    && v.eventId===value.eventId
                    && v.prices[0].id===value.prices[0].id)
                if(find){
                    find.seatIds = [...new Set([...find.seatIds, ...value.seatIds])];
                    find.prices[0].qty= find.seatIds.length;
                    find.prices[0].seatIds= find.seatIds;
                }else {
                    newLists.push(value);
                }

            }
        });
        console.log(newLists)
        return {
            list: newLists,
            qty: newLists.reduce((acc, value) => acc + value.seatIds.length, 0),

        };


    }

    removeSubSeatSelected(seatsSelected: SeatsSelected,eventId?:any) {
        let list = <SeatsSelected[]>this.subSeatSelected.value;
        if (!list) {
            list = [];
        }
        if(eventId){
            list = list.filter(value => value.eventId === eventId);
        }
        list = list.filter(
            (value) =>
                value.seatIds.join('-') !== seatsSelected.seatIds.join('-'),
        );
        this.subSeatSelected.next(list);
    }
    isSelectedSeat(seatId: number): boolean {
        let list = <SeatsSelected[]>this.subSeatSelected.value;
        if (!list) {
            list = [];
        }
        return list.some((value) => value.seatIds.includes(seatId));
    }
    getSubSeatSelected(eventId?:any): SyosSeat[] {
        let seatsSelected = <SeatsSelected[]>this.subSeatSelected.value;
        let newS: SyosSeat[] = [];
        if(eventId){
            seatsSelected = seatsSelected.filter(value => value.eventId === eventId);
        }
        seatsSelected?.forEach((value) => {
            newS = [...newS, ...value.data];
        });

        return newS;
    }
    getGroupSeatSelected(eventId?:any): SeatsSelected[] {
        let seatsSelected = <SeatsSelected[]>this.subSeatSelected.value;
        let newS: SyosSeat[] = [];
        if(eventId){
            seatsSelected = seatsSelected.filter(value => value.eventId === eventId);
        }

        return seatsSelected;
    }
    getFormatSubSeatSelected(listRemoved :SeatsSelected[]): SyosSeat[] {

        let newS: SyosSeat[] = [];

        listRemoved?.forEach((value) => {
            newS = [...newS, ...value.data];
        });
        return newS;
    }

    getPriceSeatSelected(): PriceWeb[] {
        const listRemoved = <SeatsSelected[]>this.subSeatSelected.value;
        let newS: PriceWeb[] = [];

        listRemoved?.forEach((value) => {
            newS = [...newS, ...value.prices];
        });
        return newS;
    }
    getFormatToPrice(listRemoved :SeatsSelected[]): PriceWeb[] {
         const data= this.regroupSubSeatSelectedBuyPrice(listRemoved);
        let newS: PriceWeb[] = [];
        console.log(data)
        data.list?.forEach((value) => {
            newS.push(value.prices[0])
        });
        console.log(newS)
        return newS;
    }

    removeSubSeatSelectedById(id:number,eventId?:any): SyosSeat[] {
        let list:SeatsSelected[] = cloneObjectHelps(<SeatsSelected[]>this.subSeatSelected.value) ;
        if (!list) {
            list = [];
        }
        /* Change temporairement les seatIds pour correspondre aux ids du tableau data afin de faciliter la suppression basée sur le seatId
        Cette opération est nécessaire car le premier prix inclut tous les sièges sélectionnés pour un niveau de prix, nécessaire pour le holding */
        list.forEach((value) => {   value.seatIds = value.data.map(v=>v.id)});
        const temp = list.filter(
            (seatsSelected) => !seatsSelected.seatIds.includes(id),
        );
        if (temp.length === list.length) {
            return [];
        }
        this.subSeatSelected.next(temp);
        const listRemoved = list.filter((seatsSelected) =>
            seatsSelected.seatIds.includes(id),
        );
        let newS: SyosSeat[] = [];
        listRemoved.forEach((value) => {
            newS = [...newS, ...value.data];
        });
        return newS;
    }
    clearSubSeatSelected(eventId?:any) {
        let list = <SeatsSelected[]>this.subSeatSelected.value;
        if(!list){
            return
        }
        list?.filter(value => value.eventId !== eventId);
        this.subSeatSelected.next(list.filter(value => value.eventId !== eventId));
    }

    getPriceLevel(
        priceLevels: SyosPriceLevel[] | any,
        pricesLevelId,
    ): SyosPriceLevel {
        let temp = null;
        if (!priceLevels) {
            return temp;
        }
        priceLevels.forEach((per) => {
            if (per.id === pricesLevelId) {
                temp = per;
                return temp;
            }
        });
        return temp;
    }

    getSeatStatus(seatStatus: SyosSeatStatus[], seatStatusId): SyosSeatStatus {
        let temp = null;
        if (!seatStatus) {
            return temp;
        }
        seatStatus.forEach((sets) => {
            if (sets.id === seatStatusId) {
                temp = sets;
                return temp;
            }
        });
        return temp;
    }

    getPriceForSeatBySectionId(
        Id,
        sectionId,
        isSubscription = false,
        httpParams?: HttpParams,
    ): Observable<SyosSeatDetail[]> {
        if (!Id) {
            return of([]);
        }
        if (isSubscription) {
            return this.http.get<SyosSeatDetail[]>(
                'v1/seatmap/subscription/' +
                    Id +
                    '/section/' +
                    sectionId +
                    '/details',
                { params: httpParams },
            );
        }
        return this.http.get<SyosSeatDetail[]>(
            'v1/seatmap/event/' + Id + '/section/' + sectionId + '/details',
            { params: httpParams },
        );
    }

    getPriceLevelsForSeatsMap(
        eventId,
        httpParams?: HttpParams,
    ): Observable<SyosVenueDetails> {
        return this.http.get<SyosVenueDetails>(
            'v1/seatmap/event/' + eventId + '/full',
            { params: httpParams },
        );
    }

    formatToPrices(seatsSelected: SeatsSelected): PriceWeb[] {
        const prices = [];
        seatsSelected.data.forEach((seat) => {
            const price = seat.priceSelected;
            const key = price.id;
            const temp = prices[key];
            if (temp) {
                prices[key][0].seatIds.push(seat.id);
                prices[key][0].total = 0;
            } else {
                prices[key] = [];
                price.seatIds = [];
                price.seatIds.push(seat.id);
                price.total = 0;
                prices[key].push(price);
            }
        });
        const dataFormPrices: PriceWeb[] = [];

        Object.keys(prices).forEach(function (key) {
            prices[key][0].qty = prices[key][0].seatIds.length;
            prices[key][0].total =
                (prices[key][0].serviceCharges + prices[key][0].price) *
                prices[key][0].qty;
            dataFormPrices.push(prices[key][0]);
        });
        return dataFormPrices;
    }

    getPolygons(
        Id,
        isSubscription = false,
        httpParams?: HttpParams,
    ): Observable<SyosVenueDetails> {
        if (isSubscription) {
            return this.http.get<SyosVenueDetails>(
                'v1/seatmap/subscription/' + Id + '/polygons',
                { params: httpParams },
            );
        }
        return this.http.get<SyosVenueDetails>(
            'v1/seatmap/event/' + Id + '/polygons',
            { params: httpParams },
        );
    }

    getVenueFull(
        Id,
        isSubscription = false,
        httpParams?: HttpParams,
    ): Observable<SyosVenueDetails> {
        if (!Id) {
            return this.http.get<SyosVenueDetails>(
                'v1/seatmap/event/' + Id + '/full',
                { params: httpParams },
            );
        }
        if (isSubscription) {
            return this.http.get<SyosVenueDetails>(
                'v1/seatmap/subscription/' + Id + '/full',
                { params: httpParams },
            );
        }
        return this.http.get<SyosVenueDetails>(
            'v1/seatmap/event/' + Id + '/full',
            { params: httpParams },
        );
    }

    getVenueAdmin(venueId, isPolygon = true, httpParams = new HttpParams()) {
        if (isPolygon) {
            return this.http.get<SyosVenueDetails>(
                'v1/seatmap/venues/' + venueId + '/polygons',
                { params: httpParams },
            );
        }
        return this.http.get<SyosVenueDetails>(
            'v1/seatmap/venues/' + venueId + '/full',
            { params: httpParams },
        );
    }

    getSection(id: string, typeApi?: TypeApi): Observable<SyosSection> {
        const t = 10 * 60 * 1000;
        let httpParams = new HttpParams();
        httpParams = httpParams.set('cache', String(t));
        const temp = typeApi ? '/' + typeApi : '';
        return this.http.get<SyosSection>(`v1/seatmap/sections/${id}${temp}`, {
            params: httpParams,
        });
    }
}
