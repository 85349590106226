import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Client } from '../../../models/client';
import { environment } from '../../../../../environments/environment';
import { WebsiteSaleService } from '../../services/website-sale.service';
import { TranslateService } from '@ngx-translate/core';
import {
    MessageAction,
    MessageObserverService,
} from '../../../../../projects/shared-lib/src/lib/services/message-observer.service';
import { ClientService } from '../../../client/client.service';
import { Router } from '@angular/router';
import { ClientDetails } from '../../../models/client-details';

@Component({
    selector: 'tw-client-form',
    templateUrl: './client-form.component.html',
    styleUrls: ['./client-form.component.css'],
})
export class ClientFormComponent implements OnInit {
    @Input() formGroupClient:any| UntypedFormGroup ;
    @Input() client: Client;
    lang: String;
    isGuestForm: boolean;
    environment = environment;
    load = true;
    isCheckoutPage = false;

    constructor(
        private fb: UntypedFormBuilder,
        private websiteSaleService: WebsiteSaleService,
        public translate: TranslateService,
        private clientService: ClientService,
        private messageObserverService: MessageObserverService,
        private router: Router,
    ) {
        this.isGuestForm =
            this.websiteSaleService.getLocalConfig().clientAccount === 'NO';
    }

    ngOnInit() {
        if(!this.formGroupClient){
            this.formGroupClient = new UntypedFormGroup({});
        }

        this.isCheckoutPage = this.router.url.search('mode') !== -1;

        this.formGroupClient.addControl(
            'firstname',
            this.fb.control(
                this.client && this.client.firstname
                    ? this.client.firstname
                    : '',
            ),
        );
        this.formGroupClient.addControl(
            'lastname',
            this.fb.control(
                this.client && this.client.lastname ? this.client.lastname : '',
            ),
        );
        this.formGroupClient.addControl(
            'email',
            this.fb.control(
                this.client && this.client.email ? this.client.email : '',
            ),
        );

        if (!this.isGuestForm) {
            this.formGroupClient.addControl(
                'language',
                this.fb.control(
                    this.client && this.client.language
                        ? this.client.language
                        : '',
                ),
            );
        }

        if (environment.systemValues === 'c2') {
            this.formGroupClient.addControl(
                'company',
                this.fb.control(
                    this.client && this.client.company
                        ? this.client.company
                        : '',
                ),
            );
            this.formGroupClient.addControl(
                'title',
                this.fb.control(
                    this.client && this.client.title ? this.client.title : '',
                ),
            );
        }

        this.lang =
            this.client && this.client.language
                ? this.client.language
                : this.translate.currentLang;
    }

    updateClient() {
        //console.log(this.clientService.getClientLocal())
        const client = <ClientDetails>(
            JSON.parse(JSON.stringify(this.clientService.getClientLocal()))
        );

        if (client) {
            client.firstname = this.formGroupClient.value.firstname;
            client.lastname = this.formGroupClient.value.lastname;
        }
        this.clientService.updateClient(client).subscribe(
            () => {
                this.load = true;
                this.messageObserverService.addMessage(<MessageAction>{
                    dialog: false,
                    message: 'success.update',
                    translate: true,
                    snackBar: {},
                });
            },
            (error1) => {
                this.load = true;
                this.messageObserverService.addMessage(<MessageAction>{
                    dialog: true,
                    message: error1.error.error.message,
                });
            },
        );
    }
}
