import {
    CUSTOM_ELEMENTS_SCHEMA,
    ModuleWithProviders,
    NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SERVICES_SHARED } from './services';
import { COMPONENT_SHARED } from './component';
import { MaterialModule } from '../material';
import { ErrorFormComponent } from './component/error-form/error-form.component';
import { FormContainerComponent } from './component/form/form-container/form-container.component';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TransformHtmlPipe } from './pipe/transform-html.pipe';
import { TwUrlPipe } from './pipe/tw-url-pipe/tw-url.pipe';
import { TwUppercaseDirective } from './directive/tw-uppercase.directive';
import { CardValidationDirective } from './directive/card-validation.directive';
import { EmailValidatorDirective } from './directive/email-validator.directive';
import { EqualValidatorDirective } from './directive/equal-validator.directive';
import { BiSafeUrlPipe } from './pipe/bi-safe-url.pipe';
import { TwGoogleMaps } from './pipe/tw-url-googlemaps/tw-googleMaps.pipe';
import { TwDatePipePipe } from './pipe/tw-date-pipe.pipe';
import { SecondsToTimeStringPipe } from './pipe/seconds-to-time-string.pipe';
import { PhonePipe } from './pipe/phone.pipe';
import { SocialLoginModule } from './sociallogin/sociallogin.module';
import { ScrollFixDirective } from './directive/scroll-fix.directive';

import { HideByEnvironmentDirective } from './directive/hide-byenvironment.directive';
import { ClickRedirectUrlDirective } from './directive/click-redirect-url.directive';
import { WidgetSelectEventComponent } from './component/widget-select-event/widget-select-event.component';
import { ColorBySaleStateDirective } from './directive/color-by-sale-state.directive';
import { AppBlockCopyPasteDirective } from './directive/app-block-copy-paste.directive';
import { MyNumberOnlyDirective } from './directive/my-number-only.directive';
import { CustomerTranslateDirective } from './directive/customer-translate.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GPayModule } from '../../../projects/shared-lib/src/lib/component/google-pay/g-pay.module';
import { ApplePayModule } from '../../../projects/shared-lib/src/lib/component/apple-pay/apple-pay.module';
import { UppercaseValidatorDirective } from './directive/uppercase-validator.directive';
import { LowercaseValidatorDirective } from './directive/lowercase-validator.directive';
import { SpecialCharactersValidatorDirective } from './directive/special-characters-validator.directive';
import { HasNumberValidatorDirective } from './directive/has-number-validator.directive';
import { PipeModule } from '../../../projects/shared-lib/src/lib/pipe/pipe.module';


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
        SocialLoginModule,
        MatTooltipModule,
        MatChipsModule,
        DragDropModule,
        GPayModule,
        ApplePayModule,
        PipeModule,
    ],
    declarations: [
        COMPONENT_SHARED,
        ErrorFormComponent,
        FormContainerComponent,
        TransformHtmlPipe,
        TwUrlPipe,
        TwUppercaseDirective,

        CardValidationDirective,
        EmailValidatorDirective,
        EqualValidatorDirective,
        BiSafeUrlPipe,
        TwGoogleMaps,
        TwDatePipePipe,
        SecondsToTimeStringPipe,
        PhonePipe,
        ScrollFixDirective,
        HideByEnvironmentDirective,
        ClickRedirectUrlDirective,
        WidgetSelectEventComponent,
        ColorBySaleStateDirective,
        AppBlockCopyPasteDirective,
        MyNumberOnlyDirective,
        CustomerTranslateDirective,
        UppercaseValidatorDirective,
        LowercaseValidatorDirective,
        SpecialCharactersValidatorDirective,
        HasNumberValidatorDirective,
    ],
    exports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        COMPONENT_SHARED,
        TransformHtmlPipe,
        TwUrlPipe,
        TwUppercaseDirective,
        CardValidationDirective,
        EmailValidatorDirective,
        EqualValidatorDirective,
        BiSafeUrlPipe,
        TwGoogleMaps,
        TwDatePipePipe,
        SecondsToTimeStringPipe,
        PhonePipe,
        SocialLoginModule,
        ScrollFixDirective,
        HideByEnvironmentDirective,
        ClickRedirectUrlDirective,
        WidgetSelectEventComponent,
        ColorBySaleStateDirective,
        MyNumberOnlyDirective,
        CustomerTranslateDirective,
        UppercaseValidatorDirective,
        LowercaseValidatorDirective,
        SpecialCharactersValidatorDirective,
        HasNumberValidatorDirective,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
    // constructor (@Optional() @SkipSelf() parentModule: SharedModule) {
    //     if (parentModule) {
    //         console.log(
    //             'CoreModule is already loaded. Import it in the AppModule only');
    //     }
    // }

    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [...SERVICES_SHARED],
        };
    }
}
