import {Component, OnInit} from '@angular/core';
import {ClientService} from '../../client.service';
import {ActivatedRoute, Router} from '@angular/router';
import {WebsiteSaleService} from '../../../shared/services/website-sale.service';
import {MessageAction, MessageObserverService} from'../../../../../projects/shared-lib/src/lib/services/message-observer.service';
import {TranslateService} from '@ngx-translate/core';
import {FlexPassResponse} from '../../../models/flex-pass-response';
import {  MatDialog } from '@angular/material/dialog';
import {FlexPassFilmsComponent} from '../flex-pass-films/flex-pass-films.component';
import {ExchangeFlexPassRequest} from '../../../models/exchange-flex-pass-request';
import {FlexPassExchange} from '../../../models/flex-pass-exchange';
import {HttpParams} from "@angular/common/http";
import {HelpsService} from "../../../shared/services/helps.service";

@Component({
    selector: 'tw-flex-pass',
    templateUrl: './flex-pass.component.html',
    styleUrls: ['./flex-pass.component.css'],
})
export class FlexPassComponent implements OnInit {
    flexPassResponse: FlexPassResponse = null;
    exchangeFlexPassRequest: ExchangeFlexPassRequest = <ExchangeFlexPassRequest>{};
    exchanges: any[] = [];
    start: number;
    invoiceId: number;
    load = true;
    loadPage = true;
    isSwt=false;


    constructor(private clientService: ClientService,
                private route: ActivatedRoute,
                private router: Router,
                private websiteSaleService: WebsiteSaleService,
                private messageObserverService: MessageObserverService,
                public  translate: TranslateService,
                public dialog: MatDialog,
    ) {


    }

    ngOnInit() {
       this.isSwt = HelpsService.getValueParam("swt")
        this.invoiceId = this.route.snapshot.params['invoiceId'];
        //console.log(this.invoiceId);
        this.getFlexPass()

    }

    getFlexPass() {
        this.loadPage = false;
        this.exchangeFlexPassRequest = <ExchangeFlexPassRequest>{};
        this.exchanges = [];
        this.clientService.flexPassById(this.invoiceId, new HttpParams().set('cache', '0')).subscribe(value => {
            //console.log(value)
            this.flexPassResponse = value;
            this.flexPassResponse.toBeExchange = this.flexPassResponse.toBeExchange.filter(value1 => value1!==null);
            this.start = Object.keys(this.flexPassResponse.alreadyExchanged).filter(value1 => this.flexPassResponse.alreadyExchanged[value1]).length;

            this.loadPage = true;
        }, error1 => {
            this.loadPage = true;
           // console.log(error1);
            this.messageObserverService.addMessage(<MessageAction>{dialog: true, message: error1.error.error.message});
        });
    }

    openDialog(val): void {
        const dialogRef = this.dialog.open(FlexPassFilmsComponent, {
            maxWidth: "none",
        width: "100vw",
        height: "100vh",
            data: {
                ticketId: val.key,
                films: this.flexPassResponse.toBeExchange,
                filmSelected: this.exchanges[val.key]
            },
            disableClose: true,

        });

        dialogRef.afterClosed().subscribe(result => {
            //console.log(result)
            if (result && result.value) {
                if (!this.exchangeFlexPassRequest.exchanges) {
                    this.exchangeFlexPassRequest.exchanges = [];
                }
                this.exchangeFlexPassRequest.exchanges = this.exchangeFlexPassRequest.exchanges
                    .filter(value => parseInt(value.ticketId) !== parseInt(result.key));
                this.exchangeFlexPassRequest.exchanges.push(<FlexPassExchange>{
                    performanceId: result.value.id,
                    ticketId: parseInt(result.key)
                });
                this.exchanges[result.key] = result.value;
               // console.log(this.exchangeFlexPassRequest.exchanges);
            }

        });
    }


    addFilm() {

        this.load = false;
        this.exchangeFlexPassRequest.clientId = this.clientService.getClientLocal().id;
        //console.log(this.exchangeFlexPassRequest);

        this.clientService.flexPassExchange(this.exchangeFlexPassRequest).subscribe(value => {

            this.messageObserverService.addMessage(<MessageAction>{
                dialog: false,
                message: 'success.update',
                translate: true,
                snackBar: {}
            });
            setTimeout(()=>{
               // window.history.back();
                window.close();
            },2000)
            //this.getFlexPass();
        }, error1 => {
            this.load = true;
            this.messageObserverService.addMessage(<MessageAction>{dialog: true, message: error1.error.error.message});
        });
    }

    cancel() {
        window.close();
    }
}
