import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Cart } from '../../../../../projects/shared-lib/src/lib/models/cart';
import { CartService } from '../../../shared/services/cart.service';
import { ActionCartService } from '../../../shared/services/action-cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PriceManagerService } from '../../../page/services/price-manager.service';
import {
    MessageAction,
    MessageObserverService,
} from '../../../../../projects/shared-lib/src/lib/services/message-observer.service';
import { ShippingService } from '../../../shipping/services/shipping.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { environment } from '../../../../../environments/environment';
import { lastValueFrom, Subject } from 'rxjs';
import { PageSaleService } from '../../../page/services/page-sale.service';

@Component({
    selector: 'tw-app-cart',
    templateUrl: './app-cart.component.html',
    styleUrls: ['./app-cart.component.css'],
})
export class AppCartComponent implements OnInit, OnDestroy {
    @Input() cart: Cart;
    @Input() smallCart = false;
    @Input() hideDetailCart: boolean;
    @Input() hideEditCart: boolean;
    @Output() offTimer = new EventEmitter<boolean>();
    @Input() refreshCart = true;
    @Input() isInCheckout = false;
    future: Date;
    showButton = true;
    pageReservationToken = false;
    public environment = environment;
    _sub = [];

    constructor(
        private cartService: CartService,
        private actionCart: ActionCartService,
        private router: Router,
        private route: ActivatedRoute,
        private activatedRoute: ActivatedRoute,
        private priceManagerService: PriceManagerService,
        protected messageObserverService: MessageObserverService,
        public translate: TranslateService,
        private shippingService: ShippingService,
        private analyticsService: AnalyticsService,
        private actionCartService: ActionCartService,
        public pageSaleService: PageSaleService,
    ) {
        this.showButton = this.router.url.search('cart') !== -1;
        this.pageReservationToken =
            this.router.url.search('reservationToken=') !== -1;
        if (this.showButton) {
            this.shippingService.deleteShippingSelectedLocale();
        }

        const a = this.actionCart
            .watchActionCartResponse()
            .subscribe((value) => {
                if (value.success) {
                    if (value.message && value.message.length > 4) {
                        this.messageObserverService.addMessage(<MessageAction>{
                            dialog: false,
                            message: value.message,
                            snackBar: {},
                            translate: true,
                        });
                    }

                    this.getCart().then();
                } else {
                    if (this.showButton) {
                        this.messageObserverService.addMessage(<MessageAction>{
                            dialog: true,
                            message: value.message,
                        });
                    }
                }
            });
        this._sub.push(a);
    }

    ngOnDestroy(): void {
        this._sub.forEach((value) => {
            if (value) {
                (<Subject<any>>value).unsubscribe();
            }
        });
    }

    async ngOnInit() {
        const smcart = this.route.snapshot.queryParams['small-cart'];
        if (smcart === 'true') {
            this.smallCart = true;
        }

        if (!this.cart) {
            await this.getCart();
            if (!this.isInCheckout && !this.smallCart) {
                try {
                    this.cart?.cartElements?.forEach((cartElement) => {
                        this.analyticsService.trackCartView(cartElement.prices);
                    });
                } catch (e) {}
            }
        } else {
            this.formatToGa();
            if (this.cart.reservationTime && this.cart.reservationTime > 0) {
                this.future = new Date(this.cart.reservationTime);
            }
        }
        if (this.showButton) {
            setTimeout(() => {
                window.scrollBy(0, -1000000);
            }, 1000);
        }

    }

    async getCart() {
        this.cart = await lastValueFrom(this.cartService.getCart());
        /** have to delete this code whene i use de master bd**/
        this.formatToGa();
        if (this.cart.reservationTime && this.cart.reservationTime > 0) {
            this.future = new Date(this.cart.reservationTime);
        } else {
            this.actionCartService.cartTimeOut.next(-1);
        }
    }

    formatToGa() {
        for (const cartElement of this.cart.cartElements) {
            cartElement.prices.forEach((pr) => {
                const isGa = pr.cartDetailToken.filter(
                    (value) => value.seat !== null,
                );
                pr.ga = isGa.length === 0;
                pr.rs = isGa.length > 0;
            });
        }
    }

    async timerHold(val) {
        if (val) {
            const flatPrices = [];
            this.cart.cartElements.forEach((el) => {
                el.prices.forEach((price) => {
                    flatPrices.push(price);
                });
            });
            this.analyticsService.trackCartTimeOutFromCartPrice(flatPrices);
            this.offTimer.emit(true);
            await lastValueFrom(this.cartService.clearCart());
            await this.getCart();
        }
    }

    trackContinueShopping() {
        this.analyticsService.trackContinueShopping();
    }

    goToHome() {
        this.trackContinueShopping();
        // this.router.navigate([`${this.translate.currentLang}`], { queryParamsHandling: 'merge' })
        //     .then(() => {
        //     }).catch(error => {
        //     console.error(error);
        // });
    }

    goToCheckout() {
        this.router
            .navigate(
                [this.translate.currentLang + '/client/middlewareClient'],
                { queryParamsHandling: 'merge' },
            )
            .then(() => {})
            .catch((error) => {
                //console.error(error);
            });
    }

    goToNewCheckout() {
        this.router
            .navigate([this.translate.currentLang + '/ads-pages'], {
                queryParamsHandling: 'merge',
            })
            .then(() => {})
            .catch((error) => {
                //console.error(error);
            });
    }

    goToCart() {
        const showButton = this.router.url.search('reservationToken') !== -1;
        if (showButton) {
            return;
        }
        const page = this.pageSaleService.page;
        let url = this.translate.currentLang + '/cart';
        const queryParams = {};
        if (page?.display === 'ITEMS') {
            const urlSuggestion = this.pageSaleService.getUrlPageSuggestion(
                page._links,
                null,
                this.cartService.cartSub.value,
                page.display,
                page.prices,
            );

            if (urlSuggestion) {
                url = this.translate.currentLang + '/pages/' + urlSuggestion;
                const params = urlSuggestion.split('?')[1];
                queryParams['suggestion'] = true;
                if (params?.search('promo') === -1) {
                    queryParams['promo'] = '';
                }
            }
            console.log(urlSuggestion, queryParams, url);
        }
        this.router
            .navigate([url], {
                queryParamsHandling: 'merge',
                queryParams: queryParams,
            })
            .then(() => {})
            .catch((error) => {
                // console.error(error);
            });
    }
}
