<div mat-dialog-content>
    <ul style="padding: 0">

        @for (msg of error; track msg) {
            <li>
                @if (!msg.translate) {
                    <div [innerHTML]="msg.message"></div>
                }
                @if (msg.translate && translate) {
                    <div [innerHTML]="message"></div>

                }
            </li>

        }
    </ul>



  <!--<pre>{{data | json}}</pre>-->
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Ok</button>
</div>
