@if (templateName === 'seatsSelected' && nbSeatGroup) {
    <div style="max-height: 500px; overflow: auto">
        @if (nbSeatGroup.length === 0) {
            <div
                class="tw-flex tw-row"
                style="justify-content: center; margin: 10px 0;color:black"
            >
                <div>
                    {{''| libTranslateLang:'There are no selected seats':'Il n\'y a aucun siège sélectionné.'}}
                </div>
            </div>
        } @else {
            @if(showDropDownMultiSelectSeat){
                <mat-accordion>

                    <mat-expansion-panel style="    border-width: 1px;
    border-style: solid;
    border-radius: 0;border-color: #0000002e;">
                        <mat-expansion-panel-header  [collapsedHeight]="'auto'" style="min-height: 63px">
                            <mat-panel-title >  @if (nbSeatGroup && nbSeat &&  nbSeatGroup.length >0 && nbSeat.length>0) {
                                <div
                                    class="tw-flex tw-row"
                                    style="justify-content: center; margin: 14px 0;color:black;font-weight: bold"
                                >
                                    <div [matBadge]="nbSeat.length" style="padding: 8px">
                                        {{''| libTranslateLang:'There are '+nbSeat.length +' selected seats':'Il y a '+ nbSeat.length+' siège sélectionné.'}}
                                    </div>
                                </div>
                            } </mat-panel-title>

                        </mat-expansion-panel-header>
                        <ng-container *ngTemplateOutlet="seatsSelected"></ng-container>
                    </mat-expansion-panel>
                </mat-accordion>
            } @else {
                @if (nbSeat && nbSeat.length > 0) {
                    <div style="padding: 8px">
                        {{''| libTranslateLang:'There are '+nbSeat.length +' selected seats':'Il y a '+ nbSeat.length+' siège sélectionné.'}}
                    </div>
                }

                <ng-container *ngTemplateOutlet="seatsSelected"></ng-container>
            }

        }

    </div>
}
@if (templateName === 'stepSelect') {
    <div style="max-height: 500px; overflow: auto">
        <ng-container *ngTemplateOutlet="stepSelect"></ng-container>
    </div>
}

<ng-template #seatsSelected>
    @if (nbSeatGroup && showSeatsSelected) {
        <!--   <pre> {{nbSeatGroup | json}}</pre>-->


        @for (sg of nbSeatGroup; track $index) {
            <div
                [ngStyle]="{ borderLeftColor: sg.colorPriceLevel }"
                class="border-left"
                style="color:black;margin: 10px 4px 10px 0;max-width: 450px"
            >
                <mat-card  class="seat-pl-card" style="margin: 1px"
                    [ngStyle]="{ borderLeftColor: sg.colorPriceLevel }"
                    (mousemove)="hoverSeatChange(sg)"
                    (mouseleave)="hoverLeveSeatChange(sg)"
                >
                    <mat-card-content >
                        <div
                            class="tw-flex tw-row"
                            style=" align-items: center"
                        >
                            <div class="seat-pl">
                                <strong>{{
                                    'checkout.level' | translate
                                }}</strong>
                            </div>

                            <div class="seat-pl">
                                <strong>{{
                                    'checkout.section' | translate
                                }}</strong>
                            </div>

                            <div class="seat-pl">
                                <strong>{{
                                    'checkout.row' | translate
                                }}</strong>
                            </div>

                            <div class="seat-pl">
                                <strong>{{
                                    'checkout.seat' | translate
                                }}</strong>
                            </div>
                            @if(hasOptionRemoveSeats){
                                <div class="seat-pl" style="justify-content: end">
                                    <mat-icon
                                        class="icon-pl"
                                        (click)="removeSeats($event, sg)"
                                    >delete
                                    </mat-icon>
                                </div>
                            }

                        </div>
                        @if (sg.data) {
                            @for (s of sg.data; track s.id; let i = $index) {
<!--                                {{s |json}}-->

                                <div style="">
                                    <div
                                        class="tw-flex tw-row"
                                        style="

                                            align-items: center;
                                        "
                                    >
                                        <div
                                            class="seat-pl"
                                            [ngClass]="
                                                s.levelName ? '' : 'bk-carre'
                                            "
                                        >
                                            {{ s.levelName }}
                                        </div>

                                        <div
                                            class="seat-pl "
                                            [ngClass]="
                                                s.sectionName ? '' : 'bk-carre'
                                            "
                                        >
                                            {{ s.sectionName }}
                                        </div>

                                        <div
                                            class="seat-pl"
                                            [ngClass]="
                                                s.rowName ? '' : 'bk-carre'
                                            "
                                        >
                                            {{ s.rowName }}
                                        </div>

                                        <div
                                            class="seat-pl"
                                            [ngClass]="s.name ? '' : 'bk-carre'"
                                        >
                                            {{ s.name }}
                                        </div>
                                        @if(hasOptionRemoveSeats){
                                            <div
                                                class="seat-pl " style=""

                                            >

                                            </div>
                                        }
                                    </div>

                                    @if (sg.prices && sg.prices.length === 1) {
                                        <mat-divider class="pl-divider" style="margin: 7px !important;">  </mat-divider>
                                        <div
                                            style="
                                                text-align: right;
                                                font-weight: 500;
                                                margin: 4px 5px;
                                            "
                                        >
                                            <span class="price-color">
                                                {{ sg.prices[0].price / 100 | currency : '' : 'symbol-narrow' : '' : translate.currentLang }}
                                            </span>
                                            @if (
                                                sg.prices[0].serviceCharges > 0
                                            ) {
                                                <span class="service-fees-color pricelevel-fee-temp-{{getPageId()}}">
                                                    {{'checkout.serviceCharge' | translate : { value: (sg.prices[0].serviceCharges / 100 | currency : '' : 'symbol-narrow' : '' : translate.currentLang)} }}
                                                </span>
                                            }
                                        </div>
                                    }
                                </div>

                                @if (i < sg.data.length - 1) {
                                    <mat-divider></mat-divider>
                                }
                            }
                        }
                    </mat-card-content>
                    <ng-content select="[removeSeats]"></ng-content>
<!--                    @if(hasOptionRemoveSeats){-->
<!--                        <mat-icon-->
<!--                            class="icon-pl"-->
<!--                            (click)="removeSeats($event, sg)"-->
<!--                        >delete-->
<!--                        </mat-icon>-->
<!--                    }-->

                </mat-card>
            </div>
        }
    }
    @if (showDivider){
        <mat-divider class="pl-divider">  </mat-divider>
    }

</ng-template>
<ng-template #stepSelect>
    @if(showStepper){
        <div
            class="tw-flex tw-row selectSeatsDiv-temp border-left mat-elevation-z2 border-left border-left-color"
            style="

            padding: 5px;
            margin: 10px 0;background: white;
        "
        >
            <div class="">

                <span class="mat-body-1">
                      {{
                        ''
                            | libTranslateLang
                            : 'You can select seats in groups of 2, 3, 4, up to 10 seats.'
                                : 'Vous pouvez sélectionner des sièges par groupe de 2, 3, 4 jusqu\'à 10 sièges.'
                    }}
                    <mat-icon

                        (click)="shoHelps()" style="cursor: pointer;color:var(--theme-accent-500) " > info</mat-icon>
                </span>





            </div>

            <div
                class="tw-flex"
                style="
                font-size: 20px;
                position: relative;

                align-items: stretch;
                justify-content: flex-end;
            "
            >
                <mat-form-field
                    class="add-qty"
                    appearance="outline"
                    style="width: 100px; height: 42px"
                >
                    <!--                    <mat-label>{{''| libTranslateLang:'':'Nombre de billtes par selection'}}</mat-label>-->
                    <mat-select
                        [disabled]="seatMapService.subHideMenuSelectSeatMap.value"
                        [(ngModel)]="nbStep"
                        (ngModelChange)="ngStepChange($event)"
                    >
                        @for (s of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; track s) {
                            <mat-option [value]="s" [disabled]="this.miniNbStep > s">{{ s }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>

            </div>
            <ng-content select="[btns]"></ng-content>
        </div>
    }

    <div style="max-height: 30vh; margin-bottom: 10px; overflow: auto">
        @if (
            showListPrice && itemHour && itemHour.priceLevels && itemHour.priceLevels.length > 0
        ) {
            @for (priceLevel of itemHour.priceLevels; track priceLevel) {
                <!--               <pre>{{priceLevel | json}} </pre>-->

                <tw-template-price class="tw-max-width-6 tw-flow"
                                   stylePrice="priceAndLevel"
                                   [priceLevel]="priceLevel"
                                   [showQty]="bestsSelect"
                                   [itemHour]="itemHour"
                                   [useTemplateQty]="true"
                                   [showTotal]="false"></tw-template-price>
            }
        }
    </div>
</ng-template>

<ng-template #dialogTemplateHelpSelectQty>
    <img
        src="{{translate.currentLang === 'fr' ? './assets/resources/video/helps/fr-select-seat.gif' : './assets/resources/video/helps/en-select-seat.gif'}}"

        alt="selection-qty"
        style="width: 100%"
    />
</ng-template>
