import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MessageObserverService} from '../../../../projects/shared-lib/src/lib/services/message-observer.service';
import {lastValueFrom} from 'rxjs';
import {Location} from '../../../../projects/shared-lib/src/lib/models/location';

@Component({
    selector: 'tw-location-web-view',
    templateUrl: './location-web-view.component.html',
    styleUrls: ['./location-web-view.component.css']
})
export class LocationWebViewComponent implements OnInit {
    location: Location;

    constructor(public http: HttpClient, private router: Router,
                public translate: TranslateService,
                private messageObserverService: MessageObserverService,
                private route: ActivatedRoute) {
    }

    async ngOnInit() {
        await this.getLocation();
    }

    async getLocation() {
        const id = this.route.snapshot.params['id'];;
        this.location = await lastValueFrom(this.http.get<Location>('v1/locations/' + id));

    }
}
