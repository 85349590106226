<form #user="ngForm" style="margin: auto;" (ngSubmit)="signUp(user)">
  <div class="tw-flex tw-row-2" style="justify-content: center">
    <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
      <input type="text" matInput placeholder="{{'client.firstName' | translate}}"
        ngModel required name="firstname" #firstname="ngModel">
      <mat-error>
        <tw-form-error-message [errors]="firstname.errors"></tw-form-error-message>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
      <input type="text" matInput placeholder="{{'client.lastName' | translate}}"
        ngModel required name="lastname" #lastname="ngModel">
      <mat-error>
        <tw-form-error-message [errors]="lastname.errors"></tw-form-error-message>
      </mat-error>
    </mat-form-field>
  </div>
  <div class="tw-flex tw-row-2" style="justify-content: center">


    <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
      <input type="email" matInput placeholder="{{'client.email' | translate}}"
        ngModel required name="username" #username="ngModel" #input [matAutocomplete]="auto" twValidateEmail
        [moorValidation]="false"
        (emailEmit)="getEmailList($event)">
      <mat-error>
        <tw-form-error-message [errors]="username.errors"></tw-form-error-message>
      </mat-error>
      <mat-autocomplete #auto="matAutocomplete">
        @for (option of email$; track option) {
          <mat-option [value]="option">
            {{ option }}
          </mat-option>
        }
      </mat-autocomplete>
    </mat-form-field>
    <div class="tw-input-width" style="margin: 0 20px">

    </div>


    <!--        <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">-->
    <!--            <input type="email" matInput placeholder="{{'client.zipCode' | translate}}"-->
    <!--                   ngModel required name="zipCode" #zipCode="ngModel">-->
    <!--            <mat-error>-->
    <!--                <tw-form-error-message [errors]="zipCode.errors"></tw-form-error-message>-->
  <!--            </mat-error>-->
<!--        </mat-form-field>-->
</div>
<div class="tw-flex tw-row-2" style="justify-content: center">
    <div class="tw-flex tw-column tw-input-width" style="margin: 0 20px">
        <mat-form-field  appearance="outline" >
            <input [type]="isPasswordShowing ? 'text' : 'password'"
                   matInput placeholder="{{'client.password' | translate}}" ngModel
                   name="password" #password="ngModel" (ngModelChange)="resetConfirmation()"
                   [minlength]="8"
                   [maxlength]="180"
                   twUppercaseValidator
                   twLowercaseValidator
                   twSpecialCharactersValidator
                   twHasNumberValidator

                   #passref
            >

            <tw-show-password matSuffix [inputRef]="passref"></tw-show-password>

<!--            <mat-error>-->
<!--                <tw-form-error-message [errors]="password.errors" [customFormErrors]="{minlength:'formMsg.errors.minlengthCharacters'}"></tw-form-error-message>-->
<!--            </mat-error>-->

        </mat-form-field>
        <lib-password-error-msg [password]="password"></lib-password-error-msg>


    </div>


  <mat-form-field class="tw-input-width" appearance="outline" style="margin: 0 20px">
    <input [type]="isPasswordShowing? 'text' : 'password'"
      matInput placeholder="{{'client.passwordConfirm' | translate}}" ngModel required
      name="cPassword" #cPassword="ngModel" twEqualValidator="password"
      >
    <mat-error>
      <tw-form-error-message [errors]="cPassword.errors"
      [customFormErrors]="{equalValidator: 'formMsg.errors.notSamePassword'}"></tw-form-error-message>
    </mat-error>
  </mat-form-field>

</div>
@if (environment.systemValues === 'tkp') {
  <div class="tw-flex tw-row-2" style="justify-content: center;margin:  0 15px">
    <mat-checkbox ngModel required name="privacyPolicy" #privacyPolicy="ngModel">
      @if (translate.currentLang==='fr') {
        <span style="  white-space: normal;">
          J'ai lu et j'accepte <a target='_blank' href='https://ww1.ticketpro.ca/privacy.php?languageid=2'>la politique de confidentialité et les conditions d'achat de Ticketpro.</a>
        </span>
      }
      @if (translate.currentLang!=='fr') {
        <span style="  white-space: normal;">
          I have read and agree to <a target='_blank' href='https://ww1.ticketpro.ca/privacy.php'>the Ticketpro confidentiality policy and conditions of purchase.</a>
        </span>
      }
    </mat-checkbox>
  </div>
}
<div class="tw-flex tw-row-2" style="justify-content: center;margin-top: 20px">
  <div class="tw-input-width" style="margin: 0 20px">
    <button mat-raised-button color="primary" type="submit"
      [disabled]="load && !user.valid || cPassword.value!==password.value"> {{ 'formMsg.submit' |
      translate }}
      @if (!load) {
        <div class="cssload-speeding-wheel"></div>
      }
    </button>
  </div>
  @if (isCheckoutPage) {
    <div class="tw-input-width" style="margin: 0 20px">
    </div>
  }
  @if (!isCheckoutPage) {
    <div class="tw-input-width" style="margin: 0 20px">
      <a mat-button color="primary"  (click)="goto('login')"> {{ 'title.login' | translate }} </a>
      <a mat-button color="primary"
      (click)="goto('client-forgot-password')"> {{ 'client.forgotPassword' | translate }} </a>
    </div>
  }
</div>

</form>
